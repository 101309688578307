<template>
  <l-map :zoom="zoom" :center="center" :options="mapOptions" style="height: 80%;" @update:center="centerUpdate"
    @update:zoom="zoomUpdate" ref="map">
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-feature-group ref="featureGroup">
      <template v-for="(ship, index) in assets">
        <l-polyline :key="index" v-if="ship.trail" :lat-lngs="ship.coordinates" :weight="'4'" :smoothFactor="2"
          :color="ship.dataStatus ? '#16B961' : '#FF3B30'"></l-polyline>
        <v-rotated-marker v-if="ship.lastGpsData" :key="index" :lat-lng="ship.lastLatLng" :icon="ship.icon" class="ship"
          :rotationAngle="ship.lastGpsData.track">
          <l-popup>
            <div class="row pt-3">
              <div class="col-6 d-flex align-items-center">
                <h5>{{ ship.massName }}</h5>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center pt-0">
                <h5 :class="[ship.dataStatus ? 'status online' : 'status offline']">{{ ship.dataStatus ? 'online' :
                  'offline' }}</h5>
              </div>
            </div>
            <hr />
            <div class="row info-section">
              <div class="col-6" style=" height: 50px;">
                <p style="margin-bottom:-10px; color: #1E1E1E">Location</p>
                <p style="font-weight: 1000; color: #1E1E1E">{{ ship.location }}</p>
              </div>
              <div class="col-6" style=" height: 50px;">
                <p style="margin-bottom:-10px">Total Cruise</p>
                <p style="font-weight: 1000; color: #1E1E1E">
                  {{ ship.summary &&
                    ship.summary.gps &&
                    ship.summary.gps.summary &&
                    (ship.summary.gps.summary.totalDistance / 1852).toFixed(2) || '-'
                  }}
                  NM</p>
              </div>
              <div class="col-6" style=" height: 50px;">
                <p style="margin-bottom:-10px">Avg. Speed</p>
                <p style="font-weight: 1000; color: #1E1E1E">
                  {{
                    ship.summary &&
                    ship.summary.gps &&
                    ship.summary.gps.summary &&
                    (ship.summary.gps.summary.averageSpeed / 1.852).toFixed(2) || '-'
                  }}
                  knot</p>
              </div>
              <div class="col-6" style=" height: 50px;">
                <p style="margin-bottom:-10px">Last Update</p>
                <p style="font-weight: 1000; color: #1E1E1E">{{ formatUnixTimestamp(ship.lastGpsData.timestamp) }}</p>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12 d-flex align-items-center" style="height: 15px">
                <b-form-checkbox v-model="ship.trail" name="check-button" switch class="mr-2"
                  @change="updateTrail({ massId: ship.massId, trail: ship.trail })"></b-form-checkbox>
                <p>Vessel Trail</p>
              </div>
            </div>
            <hr />
            <div class="row button-section">
              <div class="col-12 my-1">
                <b-button block variant="dark" class="tooltip-button">View Report</b-button>
              </div>
              <div class="col-12 my-1">
                <b-button block variant="outline-dark" class="tooltip-button">View Dashboard</b-button>
              </div>
            </div>
          </l-popup>
          <l-tooltip :options="tooltipOptions">
            <span v-if="ship.dataStatus && ship.lastGpsData" class="ship-label ship-online">{{ ship.massName }}</span>
            <span v-if="!ship.dataStatus && ship.lastGpsData" class="ship-label ship-offline">{{ ship.massName }}</span>
          </l-tooltip>
        </v-rotated-marker>
      </template>
    </l-feature-group>
    <l-control-polyline-measure :options="polylineMeasureOptions" />
  </l-map>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LPopup,
  LFeatureGroup,
  LTooltip,
  LPolyline,
} from 'vue2-leaflet';
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';

export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LPopup,
    LFeatureGroup,
    LTooltip,
    LPolyline,
    LControlPolylineMeasure,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
  },
  props: {
    ships: {
      type: Array,
      required: true,
    },
    zoom: {
      type: null,
    },
    center: {
      type: null,
    },
  },
  data() {
    return {
      url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      attribution: 'Map data © <a href="https://www.google.com/maps">Google Maps</a>',
      tooltipOptions: {
        permanent: true,
        directions: 'auto',
        sticky: false,
        fixed: false,
      },
      mapOptions: {
        zoomSnap: 0.5,
        attributionControl: false,
      },
      polylineMeasureOptions: {
        position: 'topleft',
        unit: 'nautical miles',
        useSubunits: true,
        clearMeasurementsOnStop: false,
        showBearings: true,
        bearingTextIn: 'In',
        bearingTextOut: 'Out',
        tooltipTextFinish: 'Click to <b>finish line</b><br>',
        tooltipTextDelete: 'Press SHIFT-key and click to <b>delete point</b>',
        tooltipTextMove: 'Click and drag to <b>move point</b><br>',
        tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
        tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',
        measureControlTitleOn: 'Turn on PolylineMeasure',
        measureControlTitleOff: 'Turn off PolylineMeasure',
        measureControlLabel: '&#8614;',
        measureControlClasses: [],
        showClearControl: true,
        clearControlTitle: 'Clear Measurements',
        clearControlLabel: '&times',
        clearControlClasses: [],
        showUnitControl: true,
        unitControlUnits: ['kilometres', 'landmiles', 'nauticalmiles'],
        unitControlTitle: {
          text: 'Change Units',
          kilometres: 'kilometres',
          landmiles: 'land miles',
          nauticalmiles: 'nautical miles',
        },
        unitControlLabel: {
          metres: 'm',
          kilometres: 'km',
          feet: 'ft',
          landmiles: 'mi',
          nauticalmiles: 'nm',
        },
        unitControlClasses: [],
        tempLine: {
          color: '#00f',
          weight: 2,
        },
        fixedLine: {
          color: '#006',
          weight: 2,
        },
        arrow: {
          color: '#000',
        },
        startCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#0f0',
          fillOpacity: 1,
          radius: 3,
        },
        intermedCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#ff0',
          fillOpacity: 1,
          radius: 3,
        },
        currentCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#f0f',
          fillOpacity: 1,
          radius: 6,
        },
        endCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#f00',
          fillOpacity: 1,
          radius: 3,
        },
      },
    };
  },
  computed: {
    assets() {
      return this.ships;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fitMapToBounds();
    });
  },
  methods: {
    formatUnixTimestamp(unixTime) {
      const date = new Date(unixTime * 1000);
      const day = `0${date.getDate()}`.slice(-2);
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const year = date.getFullYear();
      const hour = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);

      return `${day}-${month}-${year} ${hour}:${minutes}`;
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    updateTrail({ massId, trail }) {
      this.$store.dispatch('asset/updateAssetTrail', { massId, trail });
    },
    fitMapToBounds() {
      const map = this.$refs.map.mapObject;
      const group = this.$refs.featureGroup.mapObject;
      if (group) {
        map.fitBounds(group.getBounds());
      }
    },
  },
};
</script>

<style>
.ship-label {
  font-size: 13px;
  font-weight: 1000;
  text-align: center;
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 5px;
}

.ship-online {
  background-color: #16B961;
}

.ship-offline {
  background-color: #FF3B30;
}

.leaflet-popup-content-wrapper {
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: #ffffff !important;
  color: #1E1E1E;
  font-weight: 500;
  width: 270px;
  padding: 10px;
}

.status {
  margin-left: 10px;
  padding: 1px 5px;
  border-radius: 5px;
  font-weight: 600;
}

.online {
  background-color: #D7F0D8;
  color: #38AC3D;
}

.offline {
  background-color: #FFE4E4;

  color: #F24848;
}

.tooltip-button {
  width: 100%;
  font-weight: 700;
}
</style>
