<template>
  <b-container fluid>
    <b-row class="row-eq-height">
      <b-col md="12" class="mt-3">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><span v-if="devices&&devices[0]">{{devices[0].devcMass.massName}} </span>Report</h4>
            {{ activeType }}
          </template>
          <template v-slot:headerAction>
            <ul class="nav nav-pills" v-if="assetObject && assetObject[activeAssetId] && assetObject[activeAssetId].summaries">
              <li class="nav-item">
                <b-dropdown id="dropdownMenuButton4" right variant="none" data-toggle="dropdown">
                  <template v-slot:button-content>
                    Type <i class="ri-arrow-down-s-line ml-1 text-secondary"></i>
                  </template>
                  <b-dropdown-item @click.prevent="doReport('summary')">Summary</b-dropdown-item>
                  <b-dropdown-item @click.prevent="doReport('gps')">Vessel Tracking</b-dropdown-item>
                  <b-dropdown-item @click.prevent="doReport('chart')">RPM vs Fuel Cons vs Speed vs AE</b-dropdown-item>
                  <b-dropdown-item @click.prevent="doReport('mefuel')">ME Fuel Consumption</b-dropdown-item>
                  <b-dropdown-item v-if="assetObject[activeAssetId].summaries.rpm.singleEngine" @click.prevent="doReport('mesingletime')">[ME] Fuel Used VS Running Hours</b-dropdown-item>
                  <b-dropdown-item v-if="!assetObject[activeAssetId].summaries.rpm.singleEngine" @click.prevent="doReport('metime')">[PORT] Fuel Used VS Running Hours
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!assetObject[activeAssetId].summaries.rpm.singleEngine" @click.prevent="doReport('metime', 'STARBOARD')">[STB] Fuel Used VS Running Hours
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!assetObject[activeAssetId].summaries.rpm.singleEngine" @click.prevent="doReport('aefuel')">AE Fuel Consumption</b-dropdown-item>
                  <b-dropdown-item @click.prevent="doReport('table')">Data Log View</b-dropdown-item>
                  <b-dropdown-item v-if="activeAssetId == 42" @click.prevent="doReport('bunker')">Bunker In</b-dropdown-item>
                </b-dropdown>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link" v-if="!loadingDownload" @click.prevent="download">Download</a>
                <a href="#" class="nav-link" v-else style="cursor: not-allowed;">Loading...</a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link" v-if="!loadingExport" @click.prevent="doExport">Export</a>
                <a href="#" class="nav-link" v-else style="cursor: not-allowed;">Loading...</a>
              </li>
            </ul>
          </template>
          <template v-slot:body>
            <div class="mb-3" v-if="dateParam">
              <date-picker :dateParam="dateParam" :isInline="true" :isLoading="isLoading" :enableInterval="true"
                @onSubmitRange="onSubmitRange" />
            </div>
            <!-- <pre>{{devices[0].devcMass.massName}}</pre> -->
            <!-- <div class="mb-3">
              <template v-for="(item,index) in deviceTypes">
                <b-form-checkbox @change="onItemChange" v-model="item.checked" :disabled="item.disabled" name="check-button" switch inline :key="index">
                  {{ item.label }}
                </b-form-checkbox>
              </template>
            </div> -->
            <!-- <h1>{{ activeType }}</h1>
            <h1>{{ activeAssetId }}</h1> -->
            <div v-if="isLoading">
              <b-alert show variant="warning">Loading...</b-alert>
            </div>
            <div v-if="!isLoading">
              <div class="text-center" v-if="assetObject[activeAssetId]&&assetObject[activeAssetId].start">Viewing data
                from {{assetObject[activeAssetId].start | toLocalDate}} to {{assetObject[activeAssetId].end |
                toLocalDate}}</div>
              <template>
                <!-- <pre>{{assetObject[activeAssetId]}}</pre> -->
                <div v-if="activeType === 'chart'">
                  <RGraph ref="chartGraph" @onZoomed="chartIndexUpdated" @onScrolled="chartIndexUpdated"
                    :data="assetObject[activeAssetId]" />
                </div>
                <div v-if="activeType === 'metime'">
                  <RBLine ref="barlineGraph" @onZoomed="chartIndexUpdated" @onScrolled="chartIndexUpdated"
                    :type="lineType" :data="assetObject[activeAssetId]" />
                </div>
                <div v-if="activeType === 'mesingletime'">
                  <RBLineSingle ref="barlineGraph" @onZoomed="chartIndexUpdated" @onScrolled="chartIndexUpdated"
                    type="MAINENGINE" :data="assetObject[activeAssetId]" />
                </div>
                <div v-if="activeType === 'mefuel'">
                  <RStGraphSingle v-if="assetObject && assetObject[activeAssetId] && assetObject[activeAssetId].summaries.rpm && assetObject[activeAssetId].summaries.rpm.singleEngine" ref="stackedGraphSingle" @onZoomed="chartIndexUpdated" @onScrolled="chartIndexUpdated"
                    :data="assetObject[activeAssetId]" chartType="mefuel"/>
                  <RStGraph v-else ref="stackedGraph" @onZoomed="chartIndexUpdated" @onScrolled="chartIndexUpdated"
                    :data="assetObject[activeAssetId]" chartType="mefuel" />
                  <table id="fuelTable">
                    <tr>
                      <td>---</td>
                    </tr>
                  </table>
                </div>
                <div v-if="activeType === 'aefuel'">
                  <RStDailyGraph ref="stackedGraphDaily" @onZoomed="chartIndexUpdated" @onScrolled="chartIndexUpdated"
                    :data="assetObject[activeAssetId]" chartType="aefuel" />
                </div>
                <div v-if="activeType === 'table'">
                  <ReportSummary :data="assetObject[activeAssetId]" :massId="activeAssetId" class="overlay-dataview" />
                  <ReportTable :data="assetObject[activeAssetId]" @tableRangeSelected="tableRangeSelected"
                    class="overlay-dataview" />
                </div>
                <div v-if="activeType === 'summary'">
                  <div v-if="devices&&devices[0].devcMassId !== '42'"><ReportSummaryView :data="assetObject[activeAssetId]" class="overlay-dataview" />
                  </div>
                  <div v-if="devices&&devices[0].devcMassId === '42'"><ReportSummaryME :data="assetObject[activeAssetId]" class="overlay-dataview" />
                  </div>
                </div>
                <div v-if="activeType === 'gps'">
                  <!-- <pre v-if="assetObject[activeAssetId]">{{assetObject[activeAssetId].lastData}}</pre> -->
                  <div class="mt-4 mb-2">
                    <b-table-simple hover small responsive id="gpsTable">
                      <b-thead head-variant="dark" v-if="assetObject[activeAssetId].start && assetObject[activeAssetId].firstData">
                        <b-tr>
                          <b-td></b-td>
                          <b-th>Position</b-th>
                          <b-th>Time</b-th>
                          <b-th>Speed</b-th>
                          <!-- <b-th>Heading</b-th> -->
                        </b-tr>
                      </b-thead>
                      <b-tbody v-if="assetObject[activeAssetId].start && assetObject[activeAssetId].firstData">
                        <b-tr v-if="assetObject[activeAssetId].firstData.gps">
                          <b-th>Start</b-th>
                          <b-td>{{assetObject[activeAssetId].firstData.gps.latitude | latToDMS}},
                            {{assetObject[activeAssetId].firstData.gps.longitude | longToDMS}}</b-td>
                          <b-td>{{assetObject[activeAssetId].firstData.gps.timestamp | toLocalDate}}</b-td>
                          <b-th rowspan="2">{{assetObject[activeAssetId].summaries.gps.calculatedSpeed.toFixed(2)}} Knot</b-th>
                          <!-- <b-td>1</b-td> -->
                        </b-tr>
                        <b-tr v-if="assetObject[activeAssetId].lastData.gps">
                          <b-th>Last</b-th>
                          <b-td>{{assetObject[activeAssetId].lastData.gps.latitude | latToDMS}},
                            {{assetObject[activeAssetId].lastData.gps.longitude | longToDMS}}</b-td>
                          <b-td>{{assetObject[activeAssetId].lastData.gps.timestamp | toLocalDate}}</b-td>
                          <!-- <b-th>{{assetObject[activeAssetId].summaries.gps.calculatedSpeed.toFixed(2)}} Knot</b-th> -->
                        </b-tr>
                      </b-tbody>
                      <b-tfoot v-if="assetObject[activeAssetId].start && assetObject[activeAssetId].firstData">
                        <b-tr>
                          <b-th variant="primary">Total Distance</b-th>
                          <b-th colspan="3" variant="primary">{{assetObject[activeAssetId].summaries.gps.distance |
                            toNM}} Nautical Mile</b-th>
                        </b-tr>
                      </b-tfoot>
                      <b-tfoot v-else>
                        <b-th colspan="4" variant="primary" class="text-center">No Data</b-th>
                      </b-tfoot>
                    </b-table-simple>
                  </div>
                  <Map v-if="assetObject[activeAssetId].firstData&&assetObject[activeAssetId].firstData.gps&&assetObject[activeAssetId].lastData.gps"
                    :center="[0 , 0]" :data="assetObject" :showSummary="false" :disableScrollMap="'disable'"
                    :hideMapSetting="true" @mapGenerated="mapGenerated" class="row h-100"
                    style="position: sticky; height: 400px !important;" />
                  <!-- <Map
                    :center="[0 , 0]"
                    :data="assetObject"
                    :showSummary="true"
                    @mapUpdated="mapUpdated"
                    class="row h-100"
                    style="position: sticky;"/> -->
                </div>
                <div v-if="activeType === 'bunker'">
                  <ReportSummaryBunker :data="assetObject[activeAssetId]" class="overlay-dataview" />
                  <Rbunker @onZoomed="chartIndexUpdated" @onScrolled="chartIndexUpdated"
                    :data="assetObject[activeAssetId]" />
                </div>
                <!-- <div v-else>
                  <b-alert show variant="info">No Chart Type Selected</b-alert>
                </div> -->
              </template>
            </div>
            <b-modal id="summary-modal">
              <h1>Summary yay...</h1>
            </b-modal>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import requestBuilder from '@/util/request.builder';
import DeviceSvc from '@/services/DeviceSvc';

import DatePicker from '@/components/directives/date-picker';
import dateUtil from '@/util/date';

import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';

import SeriesSvc from '@/services/SeriesSvc';
import RGraph from '@/components/directives/report-graph';
import Rbunker from '@/components/directives/report-bunker';
import RStGraph from '@/components/directives/report-stacked';
import RStGraphSingle from '@/components/directives/report-stacked-single';
import RStDailyGraph from '@/components/directives/report-stacked-daily';
import RBLine from '@/components/directives/report-barline';
import RBLineSingle from '@/components/directives/report-barline-single';
import Map from '@/components/directives/iq-map';
import PdfGenerator from '@/services/PdfGenerator';
import * as XLSX from 'xlsx';
import ReportSummary from './Summary';
import ReportTable from './Table';
import ReportSummaryView from './SummaryView';
import ReportSummaryME from './SummaryViewME';
import ReportSummaryBunker from './SummaryViewBunker';

export default {
  name: 'ReportView',
  components: {
    DatePicker,
    RGraph,
    Rbunker,
    RStGraph,
    RStGraphSingle,
    RStDailyGraph,
    RBLine,
    RBLineSingle,
    ReportSummary,
    ReportTable,
    ReportSummaryView,
    ReportSummaryME,
    ReportSummaryBunker,
    Map,
  },
  data() {
    return {
      devices: undefined,
      lineType: 'PORT',
      deviceTypes: [
        {
          label: 'GPS',
          value: 'gps',
          checked: true,
          disabled: false,
        },
        {
          label: 'Flowmeter',
          value: 'flowmeter',
          checked: true,
          disabled: false,
        },
        {
          label: 'RPM',
          value: 'rpm',
          checked: true,
          disabled: false,
        },
        {
          label: 'AE',
          value: 'ae',
          checked: true,
          disabled: false,
        },
      ],
      reportTypes: {
        summary: {
          type: 'summary',
          label: 'Summary',
          tableId: '#summaryTable',
        },
        gps: {
          type: 'gps',
          label: 'GPS',
          tableId: '#gpsTable',
        },
        chart: {
          type: 'chart',
          label: 'RPM vs Fuel Consumption vs Speed',
          // tableId: '#gpsTable',
        },
        mefuel: {
          type: 'mefuel',
          label: 'ME Fuel Consumption',
          // tableId: '#gpsTable',
        },
        metime: {
          type: 'metime',
          label: 'Fuel Used VS Running Hours',
          // tableId: '#gpsTable',
        },
        aefuel: {
          type: 'aefuel',
          label: 'AE Fuel Consumption',
          // tableId: '#gpsTable',
        },
        table: {
          type: 'table',
          label: 'Data Log View',
          tableId: '#tableLogs',
        },
        mesingletime: {
          type: 'mesingletime',
          label: 'Fuel Used VS Running Hours',
          // tableId: '#gpsTable',
        },
        bunker: {
          type: 'bunker',
          label: 'Bunker In',
          // tableId: '#gpsTable',
        },
      },
      isSingleEngine: false,
      activeAssetId: undefined,
      isLoading: false,
      asset: undefined,
      assetObject: {},
      query: {},
      series: undefined,
      activeType: undefined,
      /* Default Date Parameter */
      dateParam: undefined,
      mapShot: undefined,
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { age: 38, first_name: 'Jami', last_name: 'Carney' },
      ],
      loadingExport: false,
      loadingDownload: false,
    };
  },
  async mounted() {
    this.activeType = this.$route.params.type;
    this.activeAssetId = this.$route.params.id;
    if (this.activeType === 'mefuel') {
      this.deviceTypes = [
        {
          label: 'Flowmeter',
          value: 'flowmeter',
          checked: true,
          disabled: false,
        },
      ];
    }
    // console.log('Analytics mounted... ', this.$route.params.type);
    // if (!this.assetObject[this.activeAssetId]) this.assetObject[this.activeAssetId] = {};
    console.log('assetObject>>>', this.assetObject);
    await this.load();
  },
  methods: {
    tableRangeSelected(period) {
      console.log('Range changed from table: ', period);
      this.dateParam.range.start = period.start * 1000;
      this.dateParam.range.end = period.end * 1000;
      this.dateParam.aggregatedUnit = 'MINUTE';
      this.onSubmitRange(this.dateParam);
    },
    async doExport() {
      this.loadingExport = true;
      const seriesParam = dateUtil.msToS(this.dateParam);
      seriesParam.aggregatedLength = 1;
      seriesParam.typesToGet = ['flowmeter', 'gps', 'rpm', 'ae'];
      // console.info('Export data using this params : ', this.devices, seriesParam);
      const combinedData = await SeriesSvc.getCombinedData(this.devices, seriesParam);
      console.log('Export report... ', combinedData);
      // const dataToExport = this.assetObject[this.activeAssetId];
      const refinedData = [];
      if (combinedData.summaries.rpm && combinedData.summaries.rpm.singleEngine) {
        Object.keys(combinedData.datas).forEach((timestamp) => {
          const item = combinedData.datas[timestamp];
          const constructed = {
            me_rpm: 0,
            me_fuelcons: 0,
            me_flowrate: 0,
            me_in_fm_flowrate: 0,
            me_in_fm_density: 0,
            me_in_fm_temperature: 0,
            me_out_fm_flowrate: 0,
            me_out_fm_density: 0,
            me_out_fm_temperature: 0,
            timestamp: this.$filters.toLocalDate(timestamp),
            longitude: 0,
            latitude: 0,
            speed: 0,
            distance: 0,
          };
          if (item.gps) {
            constructed.longitude = item.gps.longitude;
            constructed.latitude = item.gps.latitude;
            constructed.speed = item.gps.speed;
            constructed.distance = item.gps.distance;
          }
          if (item.rpm) {
            constructed.me_rpm = item.rpm.MAINENGINE.rpm;
          }
          if (item.flowmeter && item.flowmeter.PORT_IN && item.flowmeter.PORT_OUT) {
            constructed.me_fuelcons = item.flowmeter.portFuelCons;
            constructed.me_flowrate = (item.flowmeter.portFuelCons / (3600 / item.rpm.MAINENGINE.runningTime));
            constructed.me_in_fm_flowrate = item.flowmeter.portInFlow;
            constructed.me_in_fm_density = item.flowmeter.portInDens;
            constructed.me_in_fm_temperature = item.flowmeter.PORT_IN.temperature;
            constructed.me_out_fm_flowrate = item.flowmeter.portOutFlow;
            constructed.me_out_fm_density = item.flowmeter.portOutDens;
            constructed.me_out_fm_temperature = item.flowmeter.PORT_OUT.temperature;
          }
          // console.log(item, constructed);
          // if (item.ae.AE1) constructed.ae1_runningtime = item.ae.AE1.runningSeconds;
          // if (item.ae.AE2) constructed.ae2_runningtime = item.ae.AE2.runningSeconds;
          // if (item.ae.AE3) constructed.ae3_runningtime = item.ae.AE3.runningSeconds;
          // if (item.ae.AE4) constructed.ae4_runningtime = item.ae.AE4.runningSeconds;
          refinedData.push(constructed);
        });
      } else {
        Object.keys(combinedData.datas).forEach((timestamp) => {
          const item = combinedData.datas[timestamp];
          const constructed = {
            timestamp: this.$filters.toLocalDate(timestamp),
            longitude: null,
            latitude: null,
            speed: null,
            distance: null,
            // track: item.gps.track.toFixed(),
            // port_rpm: item.rpm.PORT.rpm,
            // port_runningtime: item.rpm.PORT.runningTime,
            // starboard_rpm: item.rpm.STARBOARD.rpm,
            // starboard_runningtime: item.rpm.STARBOARD.runningTime,
            port_fuelcons: 0,
            starboard_fuelcons: 0,
            port_flowrate: 0,
            port_in_fm_flowrate: 0,
            port_in_fm_density: 0,
            port_in_fm_temperature: 0,
            port_out_fm_flowrate: 0,
            port_out_fm_density: 0,
            port_out_fm_temperature: 0,
            starboard_in_fm_flowrate: 0,
            starboard_in_fm_density: 0,
            starboard_in_fm_temperature: 0,
            starboard_out_fm_flowrate: 0,
            starboard_out_fm_density: 0,
            starboard_out_fm_temperature: 0,
            starboard_flowrate: 0,
            me_fuelcons: 0,
          };
          if (item.gps) constructed.longitude = item.gps.longitude;
          if (item.gps) constructed.latitude = item.gps.latitude;
          if (item.gps) constructed.speed = item.gps.speed;
          if (item.gps) constructed.distance = item.gps.additional.SUM_dlgpDistance_3 / 1852;
          if (item.rpm) constructed.port_rpm = item.rpm.PORT.rpm;
          if (item.rpm) constructed.starboard_rpm = item.rpm.STARBOARD.rpm;
          if (item.flowmeter) {
            constructed.port_fuelcons = item.flowmeter.portFuelCons || 0;
            constructed.starboard_fuelcons = item.flowmeter.stbFuelCons || 0;
            constructed.port_flowrate = ((item.flowmeter.portFuelCons || 0) / (3600 / item.rpm.PORT.runningTime));
            constructed.starboard_flowrate = ((item.flowmeter.stbFuelCons || 0) / (3600 / item.rpm.STARBOARD.runningTime));
            constructed.me_fuelcons = item.flowmeter.meFuelCons || 0;
          }
          if (item.flowmeter && item.flowmeter.PORT_IN && item.flowmeter.STARBOARD_IN) {
            constructed.port_in_fm_flowrate = item.flowmeter.portInFlow || 0;
            constructed.port_in_fm_density = item.flowmeter.portInDens || 0;
            constructed.port_in_fm_temperature = item.flowmeter.PORT_IN.temperature || 0;
            constructed.starboard_in_fm_flowrate = item.flowmeter.stbInFlow || 0;
            constructed.starboard_in_fm_density = item.flowmeter.stbInDens || 0;
            constructed.starboard_in_fm_temperature = item.flowmeter.STARBOARD_IN.temperature || 0;
          }
          if (item.flowmeter && item.flowmeter.PORT_OUT && item.flowmeter.STARBOARD_OUT) {
            constructed.port_out_fm_flowrate = item.flowmeter.portOutFlow || 0;
            constructed.port_out_fm_density = item.flowmeter.portOutDens || 0;
            constructed.port_out_fm_temperature = item.flowmeter.PORT_OUT.temperature;
            constructed.starboard_out_fm_flowrate = item.flowmeter.stbOutFlow || 0;
            constructed.starboard_out_fm_density = item.flowmeter.stbOutDens || 0;
            constructed.starboard_out_fm_temperature = item.flowmeter.STARBOARD_OUT.temperature;
          }
          if (item.ae.AE1) constructed.ae1_fuelConsumption = item.ae.AE1.fuelConsumption;
          if (item.ae.AE2) constructed.ae2_fuelConsumption = item.ae.AE2.fuelConsumption;
          if (item.ae.AE3) constructed.ae3_fuelConsumption = item.ae.AE3.fuelConsumption;
          refinedData.push(constructed);
        });
      }
      console.log('refinedData', refinedData);
      const newRefinedData = refinedData.map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
          if (key === 'timestamp') {
            newItem[key] = item[key];
          } else if (key === 'latitude' || key === 'longitude') {
            newItem[key] = item[key] ? item[key] : 0;
          } else {
            newItem[key] = item[key] && typeof item[key] === 'number' ? parseFloat(item[key].toFixed(2)) : 0;
          }
        });
        return newItem;
      });
      const data = XLSX.utils.json_to_sheet(newRefinedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, `${this.devices[0].devcMass.massName.replace(' ', '-')}_${this.assetObject[this.activeAssetId].start}-${this.assetObject[this.activeAssetId].end}.xlsx`);

      this.loadingExport = false;

      // old export to csv
      // const constructedData = [];
      // refinedData.forEach((d) => {
      //   const row = [];
      //   const __d = [];
      //   Object.keys(d).forEach((_d) => {
      //     row.push(_d);
      //     __d.push(d[_d]);
      //     // console.log(_d, d[_d]);
      //   });
      //   if (constructedData.length === 0) {
      //     constructedData.push(row);
      //   }
      //   constructedData.push(__d);
      // });
      // let csvContent = '';
      // constructedData.forEach((row) => {
      //   csvContent += `${row.join(';')} \n`;
      // });
      // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
      // const objUrl = URL.createObjectURL(blob);
      // const link = document.createElement('a');
      // link.setAttribute('href', objUrl);
      // link.setAttribute('download', `${this.devices[0].devcMass.massName.replace(' ', '-')}_${this.assetObject[this.activeAssetId].start}-${this.assetObject[this.activeAssetId].end}.csv`);
      // // link.textContent = 'Click to Download'
      // document.querySelector('body').append(link);
      // link.click();
      // link.remove();
      // // console.log(csvContent);
    },
    async download() {
      this.loadingDownload = true;
      // console.log('Download report... ', this.simpleMapScreenshoter);
      const printData = {
        type: this.reportTypes[this.activeType],
        asset: this.devices[0].devcMass,
        data: this.assetObject[this.activeAssetId],
        period: `${this.$filters.toLocalDate(this.assetObject[this.activeAssetId].start)} to ${this.$filters.toLocalDate(this.assetObject[this.activeAssetId].end)}`,
      };
      console.info('bos', printData);
      if (this.activeType === 'gps') {
        await this.screenShoter.takeScreen('image', {
          mimeType: 'image/png',
        }).then((image) => {
          printData.map = image;
          // this.mapShot = image;
          // console.log('IMAGE: ', image);
        }).catch((e) => {
          console.error(e);
        });
        // printData.map = this.mapShot;
      }
      if (this.$refs.stackedGraph) {
        printData.chart = this.$refs.stackedGraph;
      }
      if (this.$refs.chartGraph) {
        printData.chart = this.$refs.chartGraph;
      }
      if (this.$refs.barlineGraph) {
        printData.chart = this.$refs.barlineGraph;
      }
      if (this.$refs.stackedGraphDaily) {
        printData.chart = this.$refs.stackedGraphDaily;
      }
      console.log('printdataa', printData);
      this.loadingDownload = false;
      PdfGenerator.generate(printData)
        // .output('dataurlnewwindow', 'report.pdf');
        .save(`${this.activeType}_${this.assetObject[this.activeAssetId].start}-${this.assetObject[this.activeAssetId].end}`);
    },
    mapGenerated(mapObject) {
      console.info('mapGenerated');
      this.screenShoter = new SimpleMapScreenshoter().addTo(mapObject);
      // setTimeout(() => {
      //   sms.takeScreen('image', {
      //     mimeType: 'image/png',
      //   }).then((image) => {
      //     this.mapShot = image;
      //     // console.log('IMAGE: ', image);
      //   }).catch((e) => {
      //     console.error(e);
      //   });
      // }, 2000);
      // console.log('MAP: ', sms);
    },
    // mapUpdated(datas) {
    //   this.assetObject[this.activeAssetId].datas = datas.assetObject[this.activeAssetId].datas;
    //   console.log('Looks like map updated: ', this.assetObject[this.activeAssetId]);
    // },
    doReport(chartType, type) {
      if (type) {
        this.lineType = type;
      }
      // console.log(chartType);
      // this.$router.push({
      //   name: 'ReportView',
      //   params: {
      //     id: this.activeAssetId,
      //     type: chartType,
      //   },
      // }).catch((err) => {
      //   console.log('Duplicate Route, Skipping... ', err);
      // });
      this.activeType = chartType;
      this.load();
    },
    chartIndexUpdated(xaxis) {
      this.dateParam = dateUtil.byInterval('custom');
      this.dateParam.range = {};
      const timeIndex = Object.keys(this.assetObject[this.activeAssetId].datas);
      let updatedStart = timeIndex[xaxis.min];
      let updatedEnd = timeIndex[xaxis.max];
      if (!timeIndex[xaxis.min]) {
        updatedStart = timeIndex[0];
      }
      if (!timeIndex[xaxis.max]) {
        updatedEnd = timeIndex[timeIndex.length - 1];
      }
      if (xaxis.min < 0) {
        const indexBefore = xaxis.min * -1;
        const interval = parseInt((parseInt(updatedEnd) - parseInt(updatedStart)) / timeIndex.length);
        updatedStart -= indexBefore * interval;
      }

      this.dateParam.range.start = parseInt(updatedStart) * 1000;
      this.dateParam.range.end = parseInt(updatedEnd) * 1000;
      this.dateParam.aggregatedUnit = 'MINUTE';
      this.onSubmitRange(this.dateParam);
      // console.log('Load data based on chart index: ', xaxis, timeIndex, timeIndex[xaxis.min], timeIndex[xaxis.max]);
    },
    async load() {
      this.isLoading = true;

      if (this.$route.query.value) {
        console.log('Data period defined... using query...');
        this.dateParam = dateUtil.queryToDateParam(this.$route.query);
        console.log('dateUtil.queryToDateParam(this.$route.query)', dateUtil.queryToDateParam(this.$route.query));
      } else {
        console.log('Data period not defined... using today...');
        this.dateParam = dateUtil.byInterval('today');
      }
      const resp = await this.getDevices();
      this.devices = resp.data.data;
      // if (!this.$route.query.value && this.devices && this.devices[0].devcMassId === '42') {
      //   this.isSingleEngine = true;
      //   this.dateParam.aggregatedUnit = 'HOUR';
      // }
      // console.log(this.activeType);
      let seriesParam = dateUtil.autoMaxData(dateUtil.msToS(this.dateParam));
      if (this.activeType === 'table') {
        seriesParam = dateUtil.msToS(this.dateParam);
      }
      if (this.activeType === 'gps') {
        seriesParam = dateUtil.msToS(this.dateParam);
        seriesParam.aggregatedLength = 8;
        // console.log('SP: ', seriesParam);
      }
      // console.log('Got devices: ', this.devices, seriesParam);
      // seriesParam.typesToGet = ['gps'];
      seriesParam.typesToGet = ['flowmeter', 'gps', 'rpm', 'ae'];
      // seriesParam.typesToGet = ['ae', 'rpm'];
      console.info('Show data devices and params', this.devices, seriesParam);
      const combinedData = await SeriesSvc.getCombinedData(this.devices, seriesParam);
      this.assetObject[this.activeAssetId] = combinedData;
      console.log('All data combined: ', combinedData);
      // this.series = await this.getDevicesData();
      // this.series.forEach((serie) => {
      //   this.assetObject[this.activeAssetId].devices[serie.data.data.devices[0]].data = serie.data.data.series;
      //   this.assetObject[this.activeAssetId].devices[serie.data.data.devices[0]].interval = serie.data.data.interval;
      // });

      this.isLoading = false;
    },
    getDevices() {
      console.log('Getting gateway device id on asset no: ', this.activeAssetId);
      const params = requestBuilder.buildFilters({
        current: 1,
        pageSize: 50,
      }, {
        devcMassId: this.activeAssetId,
        devcType: this.getEnabledDeviceTypes(),
      });
      return DeviceSvc.getDevices({ params });
      // this.devices = resp.data.data;
    },
    // getDevicesData() {
    //   console.log('Get devices data using param: ', this.dateParam);
    //   const promises = [];
    //   this.sortedDeviceKeys = [];
    //   if (!this.assetObject[this.activeAssetId].devices) this.assetObject[this.activeAssetId].devices = {};
    //   for (let i = 0; i < this.devices.length; i++) {
    //     const device = this.devices[i];
    //     const freshParam = JSON.parse(JSON.stringify(dateUtil.msToS(this.dateParam)));
    //     switch (device.devcType) {
    //       case 'gps':
    //         this.assetObject[this.activeAssetId].devices[device.devcUniqueId] = device;
    //         this.sortedDeviceKeys.unshift(device.devcUniqueId);
    //         // freshParam.primaryFunctionName = 'LAST';
    //         freshParam.functionNames = ['AVG', 'AVG', 'AVG'];
    //         freshParam.functionParams = ['@Speed', '@Track', '@Altitude'];
    //         promises.push(SeriesSvc.getAggregatedData(device, freshParam));
    //         break;
    //       case 'rpm':
    //         this.assetObject[this.activeAssetId].devices[device.devcUniqueId] = device;
    //         this.assetObject[this.activeAssetId].devices[device.devcUniqueId].data = [];
    //         this.sortedDeviceKeys.unshift(device.devcUniqueId);
    //         freshParam.functionNames = ['AVG'];
    //         freshParam.functionParams = ['@Rpm'];
    //         // freshParam.type = 'PORT';
    //         promises.push(SeriesSvc.getAggregatedData(device, freshParam));
    //         // freshParam.type = 'STARBOARD';
    //         // promises.push(SeriesSvc.getAggregatedData(device, freshParam));
    //         break;
    //       case 'ae':
    //         this.assetObject[this.activeAssetId].devices[device.devcUniqueId] = device;
    //         this.sortedDeviceKeys.push(device.devcUniqueId);
    //         freshParam.functionNames = ['AVG'];
    //         freshParam.functionParams = ['@Rpm'];
    //         promises.push(SeriesSvc.getAggregatedData(device, freshParam));
    //         break;
    //       case 'flowmeter':
    //         this.assetObject[this.activeAssetId].devices[device.devcUniqueId] = device;
    //         this.sortedDeviceKeys.push(device.devcUniqueId);
    //         freshParam.functionNames = ['AVG', 'AVG', 'AVG'];
    //         freshParam.functionParams = ['@VolFlowrate', '@Density', '@Temp'];
    //         promises.push(SeriesSvc.getAggregatedData(device, freshParam));
    //         break;
    //     }
    //   }
    //   return Promise.all(promises);
    // },
    getEnabledDeviceTypes() {
      const enabled = [];
      this.deviceTypes.forEach((type) => {
        if (type.checked) {
          enabled.push(type.value);
        }
      });
      return enabled;
    },
    onSubmitRange(date) {
      console.log('Range submit: ', date);
      this.$router.replace({
        name: 'ReportView',
        query: dateUtil.dateParamToQuery(date),
      }).catch((err) => {
        console.log('Duplicate Route, Skipping... ', err);
      });
      this.load();
    },
    onItemChange() {
      console.log('Something changed... ', this.deviceTypes);
      this.load();
    },
    showSummary() {
      // console.log('Show summary for: ', this.series);
      this.$bvModal.show('summary-modal');
    },
  },
};
</script>
