<template>
  <section class="sign-in-pages">
    <!-- <b-container fluid class="h-100">
      <b-row class="h-75" align-v="center">
        <b-col md="6" offset-md="3"> -->
          <router-view></router-view>
        <!-- </b-col>
      </b-row>
    </b-container> -->
  </section>
</template>

<script>
// import { sofbox } from '@/config/pluginInit';

export default {
  name: 'UnauthorizedLayout',
  // mounted() {
  //   sofbox.index();
  // },
  data() {
    return {

    };
  },
};
</script>

<style>
.sign-in-page {
  min-height: 100vh;
  background: linear-gradient(to top, #f8f9fa 0%, #fff 100%);
}
</style>
