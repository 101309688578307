<template>
  <div ref="mapBox" style="position: relative;" :class="activeDevcMassId.length > 0 ? 'custom-tooltip-wrapper' : false">
    <l-map id="map-wrapper" ref="myMap" :bounds="config.bounds" :zoom="config.zoom" :center="config.centerMap"
      :options="{ ...config.mapOptions, scrollWheelZoom: disableScrollMap === 'disable' ? false : true }"
      @ready="mapReady" @update:center="onCenterUpdated" @update:zoom="zoomUpdate" @mousemove="onMouseMove"
      @popupclose="handlePopupClose">
      <l-control position="bottomleft">
        <div class="legend" v-if="currentMouseCoord">
          <div class="mouseCoordinate">
            <span>{{ currentMouseCoord.lat | latToDMS }}</span>
            <span>{{ currentMouseCoord.lng | longToDMS }}</span>
          </div>
        </div>
      </l-control>

      <l-control position="bottomright" class="m-0">
        <div v-if="!isDailyReport" class="map-copyright">
          Copyright {{ new Date().getFullYear() }} Mantraz All Rights Reserved.
        </div>
      </l-control>

      <!-- <l-control-layers position="bottomright" @ready="controlReady" /> -->
      <!-- <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        :options="{ maxZoom: tileProvider.maxZoom, minZoom: 5 }"
        layer-type="base"
      /> -->

      <l-tile-layer :url="tileProviders[mapStyleIdx].url" :attribution="tileProviders[mapStyleIdx].attribution" />
      <!-- <l-map
      class="mapContainer"
      ref="myMap"
      :style="mapContainer"
      :bounds="config.bounds"
      :zoom="config.zoom"
      :center="config.centerMap"
      :options="config.mapOptions"
      @update:center="onCenterUpdated"
      @update:bounds="onBoundsUpdated"
      @update:zoom="zoomUpdate">
      <l-control-layers @ready="controlReady"/>
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"/> -->
      <!-- <l-tile-layer
        url="https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        name="Street"
      /> -->
      <!-- <l-protobuf
        v-for="(vectorProvider, providerIndex) in vectorProviders"
        :ref="`myProto_${providerIndex}`"
        :key="providerIndex"
        :url="vectorProvider.url"
        :options="vectorProvider"/> -->
      <div v-for="(wholeAsset, massId, indexx) in mapData" v-bind:key="`WholeAsset-${massId}`">
        <template v-if="wholeAsset.datas && wholeAsset.lastData">
          <l-marker :lat-lng="[wholeAsset.lastData.gps.latitude, wholeAsset.lastData.gps.longitude]"
            @click="aisClicked">
            <l-icon :options="iconOptions">
              <div v-html="getIcon(massId, wholeAsset)"></div>
            </l-icon>
            <template v-if="wholeAsset.massId">
              <l-popup v-bind:class="popupClass" style="width: 350px">
                <div class="card border-0" style="width: 300px">
                  <div class="card-body p-0 pt-2 bg-white">
                    <SummaryPopUp :asset="wholeAsset" @showDataLog="showDataLog" />
                  </div>
                </div>
              </l-popup>
              <l-tooltip :options="config.tooltipOptions">
                {{ wholeAsset.massName }}
              </l-tooltip>
            </template>
          </l-marker>
          <!-- <v-ais :lat-lng="[wholeAsset.lastData.gps.latitude, wholeAsset.lastData.gps.longitude]" :options="getMarkerOptions(wholeAsset)" @click="aisClicked">
            <template v-if="wholeAsset.massId">
              <l-popup v-bind:class="popupClass">
                <div class="card border-0" style="max-width: 300px">
                  <div class="card-body p-0 pt-2 bg-white">
                    <SummaryPopUp :asset="wholeAsset" @showDataLog="showDataLog"/>
                  </div>
                </div>
              </l-popup>
              <l-tooltip :options="config.tooltipOptions">
                {{ wholeAsset.massName }}
              </l-tooltip>
            </template>
          </v-ais> -->
          <v-polyline :opacity="0.5" :latLngs="wholeAsset.points" color="#28F385" />
          <!-- <v-polyline-decorator
            :paths="wholeAsset.points"
            :patterns="patterns"/> -->
          <!-- <pre>{{log(wholeAsset)}}</pre> -->
        </template>
        <template v-if="wholeAsset.devices">
          <div v-for="(asset, index) in wholeAsset.devices" v-bind:key="`Asset-${index}`">
            <!-- <pre>{{asset}}</pre> -->
            <div :ref="`tool_container_${asset.devcMass.massId}`" :id="`tool_container_${asset.devcMass.massId}`">
            </div>
            <div v-if="asset.devcType == 'gps'">
              <div v-if="asset.data && asset.data.length > 2 && asset.showTrail">
                <!-- <v-polyline-decorator
                  v-if="asset.data"
                  :paths="toCoordinatesArray(asset.data)"
                  :patterns="patterns"/> -->
                <!-- <v-polyline
                  v-if="asset.data"
                  :opacity="0.5"
                  :latLngs="toCoordinatesArray(asset.data)"
                  :color="deviceColors[index]"/> -->
                <!-- <ant-polyline :opacity="0.5" :paths="toCoordinatesArray(asset.data)"
                  :options="getPolilyneOptions(index)" :visible="asset.showTrail" /> -->
                <!-- <v-polyline
                  v-if="asset.data"
                  :opacity="0.5"
                  :latLngs="toCoordinatesArray(asset.data)"
                  color="#012b65"/> -->
                <v-polyline v-if="!asset.isHide" :weight="asset.trailWeight || 5" :color="getPolylineColor(asset)"
                  :opacity="asset.trailOpacity || 1" :latLngs="toCoordinatesArray(asset.data)" />
                <l-circle-marker v-if="!asset.isHide" :lat-lng="toCoordinatesArray(asset.data)[0]"
                  :radius="config.zoom - 4" :fill="true" :fillOpacity="1" :fillColor="getPolylineColor(asset)"
                  :color="'#FFFFFF'" />
              </div>
              <l-marker v-if="asset.data && asset.data.length > 0"
                :lat-lng="[asset.data[asset.data.length - 1].latitude, asset.data[asset.data.length - 1].longitude]"
                @mouseover="handleMarkerMouseEvent('enter', asset.devcMass.massId)"
                @mouseleave="handleMarkerMouseEvent('leave', asset.devcMass.massId)"
                @click="handleMarkerClick(asset.devcMass.massId)">
                <l-icon :options="iconOptions">
                  <div v-html="getIcon(index, asset)"></div>
                </l-icon>
                <l-tooltip v-show="activeDevcMassId.includes(asset.devcMass.massId)"
                  :ref="`tool_${asset.devcMass.massId}`" :options="{
                    permanent: true,
                    opacity: .9,
                    offset: [5, 0],
                    direction: 'right',
                  }">
                  <span class="custom-tooltip">
                    {{ asset.devcMass.massName }}
                  </span>
                </l-tooltip>
                <!-- <div v-show="asset.devcMass.massName === activeTooltip.massName || showAllTooltip"
                  style="position: absolute; z-index: 50000; top: 0; left: 0; background: red;"
                  :style="asset.devcMass.massName === activeTooltip.massName || showAllTooltip ? `display: block; transform: translate(${activeTooltip.x}px, ${activeTooltip.y}px);` : 'display: none;'">
                  {{asset.devcMass.massName}}</div> -->
                <l-popup :options="config.popupOptions" style="background-color: #ECF1FC !important;"
                  :keepInView="true">
                  <div class="card border-0" style="max-width: 250px; background-color: transparent;">
                    <div class="card-body p-0 pt-2">
                      <MapPopUp2 :asset="wholeAsset.devices" :showTrail="asset.showTrail"
                        :showSummary="asset.showSummary" @showDataLog="showDataLog" :indexx="indexx" :combinedData="combinedData[indexx]" @showDataSummary="showDataSummary"
                        @showSingleTooltip="showSingleTooltip" :devcMassId="asset.devcMass.massId"
                        :activeDevcMassId="activeDevcMassId.includes(asset.devcMass.massId)">
                      </MapPopUp2>
                    </div>
                  </div>
                </l-popup>
              </l-marker>
              <!-- <v-ais v-if="asset.data"
                :lat-lng="[asset.data[asset.data.length-1].latitude,asset.data[asset.data.length-1].longitude]"
                :options="getSingleMarkerOptions(asset)" @click="aisClicked">
                <l-tooltip :options="config.tooltipOptions">
                  {{ asset.devcMass.massName }}
                </l-tooltip>
                <l-popup v-bind:class="popupClass" :options="config.popupOptions">
                  <div class="card border-0" style="max-width: 300px;">
                    <div class="card-body p-0 pt-2 bg-white">
                      <MapPopUp2 :asset="wholeAsset.devices" @showDataLog="showDataLog"></MapPopUp2>
                    </div>
                  </div>
                </l-popup>
              </v-ais> -->
              <!-- <div v-if="asset.datas">{{log(asset.datas)}}</div> -->
            </div>
          </div>
        </template>
      </div>
    </l-map>
    <div v-if="!isDailyReport">
      <map-control @openMapSettings="setIsMapSettingsShown(!isMapSettingsShown)" @openSummary="openSummary()"
        @openMapStyles="setIsMapStylesShown(!isMapStylesShown)" @zoomIn="mapZoomIn()" @zoomOut="mapZoomOut()"
        :hideMapSetting="hideMapSetting" />
    </div>
    <map-settings v-if="isMapSettingsShown" @hideMapSettings="setIsMapSettingsShown(false)"
      @handelToggleShipLabel="changeShipLabelStatus" @handelToggleShipTrail="changeShipTrail"
      @handelChangeTrailOpacity="changeShipTrailOpacity" @handelChangeTrailWeight="changeShipTrailWeight"
      @handleChangeShipStatus="changeShipStatus" :isLoadingShipTrail="isLoadingShipTrail"
      @handleChangeTrailLength="changeTrailLength" :showAllTooltip="showAllTooltip"
      :isShowShipTrail="isShowShipTrail" />
    <map-styles v-if=" isMapStylesShown" @hideMapStyles="setIsMapStylesShown(!isMapStylesShown)"
      @changeMapStyle="changeMapStyle" :mapStyleIdx="mapStyleIdx" />
  </div>
</template>

<script>
import L, { Icon } from 'leaflet';
import {
  LMap, LMarker, LPolyline, LPopup, LTileLayer, LControl, LCircleMarker, LIcon,
} from 'vue2-leaflet';
// import LProtobuf from 'vue2-leaflet-vectorgrid';
import 'leaflet.vectorgrid';
// import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator';
// import Vue2LeafletTracksymbol from 'vue2-leaflet-tracksymbol';
import LTooltip from 'vue2-leaflet/src/components/LTooltip';
import mapUtil from '@/util/map.js';
// import AntPolyline from './ant-polyline';
import MapPopUp2 from '../widgets/map/MapPopUp2';
import SummaryPopUp from '../widgets/map/SummaryPopUp';
import List from '../widgets/map/List';
// import vectorStyle from './iq-map.styles.js';
import 'leaflet-providers';
// import 'leaflet-panel-layers';
import MapControl from './map-control';
import MapSettings from './map-settings';
import MapStyles from './map-styles.vue';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: 'iq-map',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    List,
    // eslint-disable-next-line vue/no-unused-components
    MapPopUp2,
    SummaryPopUp,
    LMap,
    // eslint-disable-next-line vue/no-unused-components
    LTileLayer,
    // eslint-disable-next-line vue/no-unused-components
    LMarker,
    // eslint-disable-next-line vue/no-unused-components
    LPopup,
    // eslint-disable-next-line vue/no-unused-components
    LTooltip,
    // eslint-disable-next-line vue/no-unused-components
    'v-polyline': LPolyline,
    LCircleMarker,
    // 'v-ais': Vue2LeafletTracksymbol,
    // eslint-disable-next-line vue/no-unused-components
    // 'v-polyline-decorator': Vue2LeafletPolylineDecorator,
    // LProtobuf,
    // LControlLayers,
    LControl,
    // AntPolyline,
    // LLayerGroup,
    MapControl,
    MapSettings,
    MapStyles,
    LIcon,
  },
  // props: ['center', 'data', 'mapConfig', 'showSummary', 'isLoadingShipTrail', 'disableScrollMap', 'hideMapSetting'],
  props: {
    combinedData: { type: Object },
    center: { type: Object },
    data: { type: Array },
    mapConfig: { type: Object },
    showSummary: { type: Boolean },
    isLoadingShipTrail: { type: Boolean },
    disableScrollMap: { type: Boolean },
    hideMapSetting: { type: Boolean },
    isDailyReport: { type: Boolean, default: false },
    isZoomIn: { type: Boolean, default: false },
    isZoomOut: { type: Boolean, default: false },
  },
  mounted() {
    this.updateMap();
    console.info('mounted', this.combinedData);
    // const overLayers = [
    //   {
    //     name: 'Drinking Water',
    //     icon: '<i class="icon icon-water"></i>',
    //     // layer: L.geoJson(WaterGeoJSON)
    //   },
    // ];
    // console.log('L : ', L.Control);
    // this.$refs.myMap.mapObject.addControl(new L.Control.PanelLayers(overLayers));
    // const legend = L.control({ position: 'bottomright' });
    // legend.onAdd = function onAdd() {
    //   this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
    //   this.update();
    //   return this._div;
    // };

    // legend.addTo(this.$refs.myMap.mapObject);
    // console.log(legend);
    // console.log(L.tileLayer.provider('Stamen.Watercolor'));
    // this.$nextTick(() => {
    //   // const lay = L.marker([50.505, 30.57]);
    //   // console.log('Lae : ', lay);
    //   // this.$refs.myMap.mapObject.addLayer(L.marker([50.505, 30.57]));
    //   console.log('A : ', this.$refs.myMap.mapObject._layers);
    //   // L.control.layers(baseMaps).addTo(map);
    //   // this.vectorProviders.forEach((vectorProvider, providerIndex) => {
    //   //   // console.log(vectorProvider, this.$refs[`mapProto_${providerIndex}`]);
    //   //   this.$refs.myMap.layerControl.addLayer({ layerType: 'overlay', name: vectorProvider.name, mapObject: this.$refs[`mapProto_${providerIndex}`].mapObject });
    //   // });

    //   // console.log(this.$refs.myMap.layerControl.addLayer(L.marker([50.505, 30.57]));
    // });
  },
  computed: {
    // mapCenter() {
    //   const assetArray = Object.keys(this.data);
    //   assetArray.forEach((assetId) => {
    //     if (this.data[assetId].datas) {
    //       console.log('Get center from this...', this.data[assetId].datas);
    //     }
    //   });
    //   return this.config.centerMap;
    // },
    mapContainer() {
      const height = window.innerHeight;
      if (this.mapConfig && this.mapConfig.fullHeight) {
        return `height: ${height - 75}px;width: 100%;position: relative;`;
      }
      return `height: ${(height - 75) / 2}px !important;width: 100%;position: relative; overflow:hidden;`;
    },
  },
  data() {
    return {
      currentMouseCoord: undefined,
      popupClass: 'freezePopup',
      mapData: undefined,
      tileProviders: [
        {
          name: 'Google Satelite',
          maxZoom: 16,
          visible: true,
          url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
          attribution: 'Google Satelite',
        },
        // {
        //   name: 'Light',
        //   maxZoom: 14,
        //   visible: false,
        //   url: 'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png',
        //   attribution: 'OSM & Carto',
        // },
        // {
        //   name: 'Dark',
        //   maxZoom: 14,
        //   visible: false,
        //   url: 'https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png',
        //   attribution: 'OSM & Carto',
        // },
        // {
        //   name: 'OpenStreetMap Green',
        //   visible: false,
        //   maxZoom: 14,
        //   attribution:
        //     '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        //   url: 'https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png',
        // },
        {
          name: 'OpenStreetMap Blue',
          visible: true,
          maxZoom: 16,
          url: 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png',
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        // {
        //   name: 'Black & White',
        //   visible: false,
        //   maxZoom: 14,
        //   url: 'https://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
        //   attribution:
        //     'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        // },
        // {
        //   name: 'OpenTopoMap',
        //   visible: false,
        //   maxZoom: 14,
        //   url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
        //   attribution:
        //     'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        // },
      ],
      vectorProviders: [
        {
          name: 'Administrative Area',
          visible: false,
          attribution: '© BMKG',
          maxNativeZoom: 14,
          url: 'https://signature.bmkg.go.id/data/administration/{z}/{x}/{y}.pbf',
          vectorTileLayerStyles: {
            subdistrict: {
              color: '#012b65',
              weight: 1,
              opacity: 0.2,
            },
            province: {
              color: '#012b65',
              weight: 1,
              opacity: 0.2,
            },
          },
        },
      ],
      // opts: {
      //   attribution: '© BMKG',
      //   maxNativeZoom: 14,
      //   // vectorTileLayerStyles: vectorStyle,
      // },
      // tilesUrl: 'https://basemaps.arcgis.com/v1/arcgis/rest/services/World_Basemap/VectorTileServer/tile/{z}/{y}/{x}.pbf',
      // tilesUrl: 'https://signature.bmkg.go.id/data/administration/{z}/{x}/{y}.pbf',
      deviceColors: [],
      deviceId: undefined,
      hoveredDevice: undefined,
      patterns: [
        {
          offset: 2,
          repeat: 25,
          // symbol: L.Symbol.arrowHead({ pixelSize: 8, polygon: false, pathOptions: { stroke: true, color: '#FFD700' } }),
        },
        // {
        //   offset: 0,
        //   repeat: 25,
        //   symbol: L.Symbol.dash({ pixelSize: 0 }),
        // },
      ],
      config: {
        maxZoom: 16,
        zoom: 10,
        // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        url: 'https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', // sattelite hybrid
        currentZoom: 10,
        mapOptions: {
          zoomSnap: 0.5,
          attributionControl: false,
          zoomControl: false,
          scrollWheelZoom: true,
        },
        centerMap: undefined,
        bounds: undefined,
        iconSize: 25,
        // iconUrl: 'require(\'../../assets/icons/ship-icon.svg\')',
        tooltipOptions: {
          permanent: false,
        },
        popupOptions: {
          minWidth: 250,
          backgroundColor: '#ECF1FC',
        },
        padding: null,
      },
      isMapSettingsShown: false,
      isMapStylesShown: false,
      circle: {},
      activeTooltip: {
        massName: undefined,
        x: undefined,
        y: undefined,
      },
      showAllTooltip: false,
      mapStyleIdx: 0,
      currentCenter: undefined,
      activeDevcMassId: [],
      isShowShipTrail: false,
      iconOptions: {
        iconSize: [50, 50],
        popupAnchor: [0, (-1 * 50) / 2],
        tooltipAnchor: [10, 0],
        className: 'ships',
        rotationAngle: 90,
      },
      persistentActiveDevcMassId: [],
    };
  },
  created() {
    // console.log('Dede : ', this.data);
    this.generateColor(Object.keys(this.data).length);
  },
  methods: {
    handleMarkerClick(massId) {
      // console.info('==== handle marker click');
      this.showSingleTooltip({ value: true, currentDevcMassId: massId });
      this.persistentActiveDevcMassId = [...this.persistentActiveDevcMassId.filter((item) => item !== massId), massId];
    },
    handlePopupClose() {
      // console.info('==== popup closed');
      this.persistentActiveDevcMassId = [];
      // this.activeDevcMassId = [...this.activeDevcMassId.filter((item) => !this.persistentActiveDevcMassId.includes(item))];
      // this.activeDevcMassId = [];
    },
    handleMarkerMouseEvent(type, massId) {
      // console.info('==== handleMarkerMouseEvent', type, massId, this.persistentActiveDevcMassId);
      const tooltipPersistent = this.persistentActiveDevcMassId.find((item) => item === massId);
      // console.info('==== tooltipPersistent', this.showAllTooltip, tooltipPersistent);
      if (!this.showAllTooltip && !tooltipPersistent) {
        if (type === 'enter') {
          this.showSingleTooltip({
            value: true,
            currentDevcMassId: massId,
          });
        } else if (type === 'leave') {
          this.showSingleTooltip({
            value: false,
            currentDevcMassId: massId,
          });
        }
      }
    },
    showSingleTooltip(e) {
      if (e.value) {
        this.activeDevcMassId = [...this.activeDevcMassId.filter((item) => item !== e.currentDevcMassId), e.currentDevcMassId];
      } else {
        this.activeDevcMassId = [...this.activeDevcMassId.filter((item) => item !== e.currentDevcMassId)];
      }
    },
    aisClicked() {
      // console.log('AC : ', e);
      this.popupClass = 'unfreezePopup';
    },
    onMouseMove(e) {
      this.currentMouseCoord = e.latlng;
      // console.log('MM : ', e.latlng.lat);
    },
    degrees_to_radians(degrees) {
      const pi = Math.PI;
      return degrees * (pi / 180);
    },
    getMarkerOptions(wholeAsset) {
      // console.log('gpsData : ', wholeAsset.lastData.gps);
      let shipColor = '#128200';
      if (wholeAsset.fromNow > 600) {
        shipColor = '#d61515';
      }

      return {
        trackId: wholeAsset.lastData.gps.deviceId,
        fill: true,
        fillColor: shipColor,
        fillOpacity: 1.0,
        stroke: true,
        color: '#000000',
        opacity: 1.0,
        weight: 1.0,
        speed: wholeAsset.lastData.gps.speed,
        course: this.degrees_to_radians(wholeAsset.lastData.gps.track), // radians
        heading: this.degrees_to_radians(wholeAsset.lastData.gps.track), // radians
        // course: 1.0, // radians
        // heading: 1.0, // radians
        size: 24,
        // defaultSymbol: [0.75, 0, 0.5, 0.3, -0.5, 0.3, -0.25, 0, -0.5, -0.3, 0.5, -0.3],
        defaultSymbol: [1.2, 0, 0.5, 0.3, -0.5, 0.3, -0.3, 0, -0.5, -0.3, 0.5, -0.3],
        // data: { name: 'Boat 2', custom: 'other info' },
      };
    },
    getSingleMarkerOptions(asset) {
      // console.log('gpsData : ', new Date().getTime() - (asset.data[0].timestamp * 1000));
      // return;
      let shipColor = '#128200';
      if (new Date().getTime() - (asset.data[asset.data.length - 1].timestamp * 1000) > 600000) {
        shipColor = '#d61515';
      }

      return {
        trackId: asset.devcUniqueId,
        fill: true,
        fillColor: shipColor,
        fillOpacity: 1.0,
        stroke: true,
        color: '#000000',
        opacity: 1.0,
        weight: 1.0,
        speed: asset.data[0].speed,
        course: this.degrees_to_radians(asset.data[0].track), // radians
        heading: this.degrees_to_radians(asset.data[0].track), // radians
        // course: 1.0, // radians
        // heading: 1.0, // radians
        size: 24,
        // defaultSymbol: [0.75, 0, 0.5, 0.3, -0.5, 0.3, -0.25, 0, -0.5, -0.3, 0.5, -0.3],
        defaultSymbol: [1.2, 0, 0.5, 0.3, -0.5, 0.3, -0.3, 0, -0.5, -0.3, 0.5, -0.3],
        // data: { name: 'Boat 2', custom: 'other info' },
      };
    },
    getPolilyneOptions(value) {
      // console.log(index, this.deviceColors);
      console.info('getPolilyneOptions', value);
      return { color: '#28F385', weight: value || 5 };
    },
    controlReady(mapObject) {
      // console.log('Control ready... ', L.tileLayer.provider('Stamen.Watercolor'));
      // L.tileLayer.provider('Stamen.Watercolor').addTo(this.$refs.myMap.mapObject);
      // mapObject.addBaseLayer(L.tileLayer.provider('Stamen.Watercolor'), 'Watercolor');
      // mapObject.addBaseLayer(L.tileLayer.provider('Esri.WorldImagery'), 'WorldImagery');
      mapObject.addOverlay(L.tileLayer.provider('OpenWeatherMap.Wind', { apiKey: '226f2e1f5e72b52a760459f5576683ac' }), 'Wind');
      mapObject.addOverlay(L.tileLayer.provider('OpenWeatherMap.Rain', { apiKey: '226f2e1f5e72b52a760459f5576683ac' }), 'Rain');
      this.vectorProviders.forEach((vectorProvider) => {
        // console.log('Proto : ', vectorProvider);
        mapObject.addOverlay(L.vectorGrid.protobuf(vectorProvider.url, vectorProvider), vectorProvider.name);
        //   this.$refs.myMap.layerControl.addLayer({ layerType: 'overlay', name: vectorProvider.name, mapObject: this.$refs[`mapProto_${providerIndex}`].mapObject });
      });
    },
    log(data) {
      console.log('Show this data : ', data);
    },
    // datasToCoordinatesArray(datas) {
    //   // const coordinates;
    //   console.log('PPP : ', datas);
    // },
    mapReady() {
      // this.$refs.myMap.scrollWheelZoom.disable();
      console.info('map generated');
      this.$emit('mapGenerated', this.$refs.myMap.mapObject);
    },
    updateMap(disabledUpdateMap, showLabel) {
      const constructedMapData = mapUtil.constructMapData(this.data, this.$refs.mapBox);
      this.mapData = constructedMapData.assetObject;
      // console.log('This is constructed map config : ', constructedMapData.config);
      // console.log('This is mapData : ', this.mapData);
      console.log('This is mapData : ', this.disableScrollMap);
      console.log('constructedMapData : ', constructedMapData);
      if (this.disableScrollMap === 'disable') {
        this.config.mapOptions.scrollWheelZoom = false;
      } else {
        this.config.mapOptions.scrollWheelZoom = true;
      }
      console.log('this.config.mapOptions.scrollWheelZoom : ', this.config.mapOptions.scrollWheelZoom);
      if (constructedMapData.config.center && !disabledUpdateMap) {
        if (this.isZoomOut && !this.isZoomIn) {
          this.config.zoom = 9;
        } else if (this.isZoomIn && !this.isZoomOut) {
          this.config.zoom = 12.5;
        } else {
          this.config.zoom = constructedMapData.config.zoom;
        }
        this.config.centerMap = constructedMapData.config.center;
        console.log('reset center');
      } else {
        // this.config.centerMap = this.currentCenter;
        this.config.padding = [1600, 1200];
        console.log('set center based latest marker');
      }
      if (constructedMapData.config.zoom) {
        this.mapReady();
        console.info('this.config', showLabel, this.config);
      }
      // this.$emit('mapUpdated', constructedMapData);
    },
    toCoordinatesArray(datas) {
      // console.log('aaaa : ', datas);
      const coordinates = [];
      datas.forEach((data) => {
        coordinates.push([data.latitude, data.longitude]);
        // console.log(data);
      });
      // console.info('coordinates', coordinates);
      return coordinates;
    },
    onCenterUpdated(center) {
      // console.log('Center updated... ', center);
      this.config.centerMap = center;
    },
    // onBoundsUpdated(bounds) {
    //   console.log('Bounds updated... ', bounds.getCenter());
    //   // this.config.centerMap = center;
    // },
    onDeviceClicked() {
    },
    generateColor(dataLength) {
      let color = '';
      for (let i = 0; i < dataLength + 1; i++) {
        color = `#${Math.floor(Math.random() * 16777215)
          .toString(16)}`;
        this.deviceColors.push(color);
      }
    },
    getPolylineColor(asset) {
      let shipColor = '#28F385';
      if (asset.data) {
        if (new Date().getTime() - (asset.data[asset.data.length - 1].timestamp * 1000) > 600000) {
          shipColor = '#d61515';
        }
      } else if (asset.lastData) {
        if (new Date().getTime() - (asset.lastData.gps.timestamp * 1000) > 600000) {
          shipColor = '#d61515';
        }
      }
      return shipColor;
    },
    getIcon(index, asset) {
      // console.log('colorize : ', asset);
      // const okColor = '#128200';
      // console.log(index, asset);
      let shipColor = '#28F385';
      let rotationDegree = 0;
      if (asset.data) {
        rotationDegree = asset.data[0].track;
        if (new Date().getTime() - (asset.data[asset.data.length - 1].timestamp * 1000) > 600000) {
          shipColor = '#d61515';
        }
      } else if (asset.lastData) {
        rotationDegree = asset.lastData.gps.track;
        if (new Date().getTime() - (asset.lastData.gps.timestamp * 1000) > 600000) {
          shipColor = '#d61515';
        }
      }
      const iconName = asset.massName;
      const iconSize = 50;
      const markerStyles = `
        // left: -1.5 rem;
        // right: -1.5 rem;
        position: relative;
        // border-radius: 3 rem 3 rem 0;
        transform: rotate(${rotationDegree}deg);
        -webkit-transform: rotate(${rotationDegree}deg);
        -ms-transform: rotate(${rotationDegree}deg);
        transform: rotate(${rotationDegree}deg);
        color: ${shipColor};
        // z-index: 99999 !important;
        // background-color: yellow;
        // width: 100%;
        // height: 100%;
        `;
      return `
          <div style="${markerStyles}">
          <svg width="${iconSize}"
              height="${iconSize}" aria-labelledby="${iconName}" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_538_10839)">
            <g filter="url(#filter0_d_538_10839)">
            <path d="M31.7991 2.7782C35.4825 6.60131 37.8059 9.62539 39.2326 12.6397C40.7559 15.8581 41.3001 19.1585 41.2554 23.5765L41.2553 23.5765L41.2553 23.5877L41.2154 59.6562C37.4009 60.4221 34.4154 60.7741 31.5169 60.7487C28.6325 60.7235 25.788 60.3243 22.2511 59.5446L22.291 23.4633C22.291 23.4626 22.291 23.4618 22.291 23.4611C22.3119 19.0574 22.8918 15.7649 24.425 12.5599C25.8592 9.5618 28.1646 6.56103 31.7991 2.7782Z" fill="${shipColor}" stroke="white" stroke-width="2.5"/>
            <path opacity="0.2" d="M23.8292 39.8435L23.8292 57.9158C29.1621 59.3614 33.8284 59.3614 39.828 57.9157L39.828 38.3976C39.8906 34.0965 37.6043 33.0698 31.8286 29C26.1227 33.0314 23.8582 35.5573 23.8292 39.8435Z" fill="black"/>
            </g>
            </g>
            <defs>
            <filter id="filter0_d_538_10839" x="15.0488" y="-0.487804" width="33.4103" height="74.3902" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="5.95122"/>
            <feGaussianBlur stdDeviation="2.97561"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_538_10839"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_538_10839" result="shape"/>
            </filter>
            <clipPath id="clip0_538_10839">
            <rect width="63" height="63" fill="white"/>
            </clipPath>
            </defs>
            </svg>
          </div>
        `;
    },
    zoomUpdate(zoom) {
      console.info('zoom updated', zoom);
      if (zoom <= 16) {
        this.config.zoom = zoom;
      }
      this.config.tooltipOptions = {
        permanent: !this.config.tooltipOptions.permanent,
      };
    },
    showDataLog(v) {
      console.log('emit called', v);
      this.$emit('showDataLog', v);
    },
    showDataSummary(value) {
      console.info('emit showDataSummary', value);
      this.$emit('showDataSummary', value);
    },
    mapZoomIn() {
      ++this.config.zoom;
      console.info(this.config.zoom);
    },
    mapZoomOut() {
      --this.config.zoom;
    },
    openSummary() {
      this.isMapSettingsShown = false;
      this.isMapStylesShown = false;
      this.showDataSummary({
        showSummary: true,
        // device: {},
        // dateParam: {
        //   mode: 'last',
        //   duration: 1,
        //   unit: 'month',
        //   range: {
        //     start: undefined,
        //     end: undefined,
        //   },
        //   aggregatedUnit: 'MINUTE',
        // },
      });
    },
    setIsMapSettingsShown(state) {
      console.info('setIsMapSettingsShown', state);
      this.showDataSummary({ showSummary: false });
      this.isMapSettingsShown = state;
      this.isMapStylesShown = false;
    },
    setIsMapStylesShown(state) {
      console.info('setIsMapStylesShown', state);
      this.showDataSummary({ showSummary: false });
      this.isMapSettingsShown = false;
      this.isMapStylesShown = state;
    },
    changeShipLabelStatus(value) {
      console.info('change ship label', value);
      // this.config = {
      //   ...this.config,
      //   tooltipOptions: {
      //     permanent: value,
      //   },
      // };
      // L.tooltip({
      //   permanent: true,
      // });
      // L.unbindTooltip().bindTooltip(tooltip, {
      //   permanent: value,
      // });
      // this.updateMap(true);
      if (value) {
        this.activeDevcMassId = Object.keys(this.mapData);
      } else {
        this.activeDevcMassId = [];
      }
      console.info(this.activeDevcMassId);
      this.showAllTooltip = value;
      // this.$emit('toggleShipLabel', value);
      // this.$refs["my_box_a"].getBoundingClientRect()
      // console.info('change ship label 2', this.$refs.mass_25);
      // // this.$refs.myMap.on()
      // console.info('change ship myMap', this.$refs.myMap);
      /*
      <div class="leaflet-tooltip leaflet-zoom-animated leaflet-tooltip-left" style="opacity: 0.9;transform: translate3d(414px, 225px, 0px);"><div data-v-75e7c4c2=""> TB Megawati 25 trail:tidak weight:5 opacity: data:1 tooltip: marker hide:false </div></div>
      */
      // const objTo = document.getElementById('tool_container_23');
      // const divtest = document.createElement('div');
      // divtest.className = 'leaflet-tooltip leaflet-zoom-animated leaflet-tooltip-left';
      // divtest.style = 'opacity: 0.9;transform: translate3d(414px, 225px, 0px); z-index: 9999';
      // divtest.innerHTML = 'MEGAWATI SUKARNO';
      // objTo.appendChild(divtest);
      // const markerEl = document.getElementById('marker_23');
      // const style = window.getComputedStyle(document.querySelector('#marker_23')).transform;
      // const markerRef = this.$refs.marker_23;
      // console.info('ref marker megawati', this.$refs.marker_23);
      // console.info('getElementById marker megawati', this.$refs.myMap);
      // console.info('getComputedStyle marker megawati', L.latLngBounds([0.36034715, 117.57565643]));
    },
    changeShipTrail(value) {
      console.info('change ship trail', value);
      this.isShowShipTrail = value;
      this.$emit('toggleShipTrail', value);
    },
    changeShipTrailWeight(value) {
      console.info('change ship trail weight', value);
      this.$emit('toggleShipTrailWeight', value);
    },
    changeShipTrailOpacity(value) {
      console.info('change ship trail opacity', value);
      this.$emit('toggleShipTrailOpacity', value);
    },
    changeShipStatus(value) {
      console.info('change ship status', value);
      this.$emit('changeShipStatus', value);
    },
    async changeTrailLength(value) {
      console.info('change ship trail length', value);
      Object.keys(this.data).forEach((key) => {
        // const tsObject = asset[Object.keys(asset)[key]].data[asset[Object.keys(asset)[0]].data.length - 1].timestamp * 1000;
        const asset = this.data[key].devices;
        console.info('value devices', asset[Object.keys(asset)[0]]);
        console.info('value devices date', this.$date.utc(new Date().setDate(new Date().getDate() - Number(value))).unix());
        const tsObject = asset[Object.keys(asset)[0]].data[asset[Object.keys(asset)[0]].data.length - 1].timestamp * 1000;
        this.$emit('showDataLog', {
          // deviceId: Object.keys(asset)[0],
          showTrail: true,
          device: asset[Object.keys(asset)[0]],
          dateParam: {
            mode: 'period',
            aggregatedUnit: 'MINUTE',
            aggregatedLength: 5,
            range: {
              // start: this.$date.utc(new Date(tsObject).setDate(new Date(tsObject).getDate() - 7)).unix(), // awalnya 1 minggu
              // start: this.$date.utc(new Date(tsObject).setDate(new Date(tsObject).getDate() - Number(value))).unix(), // jadi 1 hari saja
              start: this.$date.utc(new Date(tsObject).setDate(new Date(tsObject).getDate() - Number(value))).unix(),
              end: this.$date.utc(new Date(tsObject).getTime()).unix(),
            },
          },
        });
      });
    },
    openPopUp(e, massId) {
      console.info('openPopUp mouse enter', e, massId);
      const rere = `tool_${massId}`;
      // this.$refs[rere][0].options.permanent = true;
      console.info('2 openPopUp mouse enter', this.$refs[rere][0]);
      this.activeTooltip = {
        massName: massId,
        x: e.layerPoint.x,
        y: e.layerPoint.y,
      };
    },
    closePopUp() {
      console.info('closePopUp mouse leave');
      this.activeTooltip = {
        massName: undefined,
        x: undefined,
        y: undefined,
      };
    },
    changeMapStyle(value) {
      console.info('1changed');
      this.mapStyleIdx = value;
    },
  },
  // watch: {
  //   center: {
  //     immediate: true,
  //     handler(v) {
  //       this.onCenterUpdated(v);
  //     },
  //   },
  // },
};
</script>

<style scoped>
@media (max-width: 767.98px) {
  .content {
    margin-left: 0;
  }
}

.map-container {
  height: 500px;
  /* position: relative; */
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  z-index: 999;
  color: black;
}

.right-sidebar {
  height: 100%;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 999;
  color: black;
}

.sidenav {
  height: 100%;
  width: 300px;
  z-index: 999;
  top: 0;
  right: 0;
  background-color: whitesmoke;
  opacity: 0.9;
  overflow-x: hidden;
}

.map-menu-wrapper {
  color: #0084ff;
  width: 20px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.map-copyright {
  background: rgba(0, 0, 0, 0.25);
  padding: 5px 8px;
  color: #ffffff;
}
</style>
