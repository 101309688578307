import moment from 'moment';

const state = {
  date: {
    start: moment().startOf('day'),
    end: moment(),
  },
  currentMassId: null,
  interval: 'hour',
};

const mutations = {
  SET_DATE(state, { start, end }) {
    state.date = { start, end };
  },
  SET_INTERVAL(state, interval) {
    state.interval = interval;
  },
  SET_CURRENT_MASS_ID(state, id) {
    state.currentMassId = id;
  },
};

const actions = {
  setDate({ commit }, { start, end }) {
    commit('SET_DATE', { start, end });
  },
  setCurrentMassId({ commit }, id) {
    commit('SET_CURRENT_MASS_ID', id);
  },
  setInterval({ commit }, interval) {
    commit('SET_INTERVAL', interval);
  },
};

const getters = {
  date: (state) => state.date,
  currentMassId: (state) => state.currentMassId,
  interval: (state) => state.interval,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
