import moment from 'moment';

function lastWeek() {
  const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
  const day = beforeOneWeek.getDay();
  const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
  return {
    monday: new Date(beforeOneWeek.setDate(diffToMonday)),
    sunday: new Date(beforeOneWeek.setDate(diffToMonday + 6)),
  };
}

function getIntervals() {
  return [
    {
      label: 'Last 30 Days',
      value: 'last_days_30',
      mode: 'last',
      duration: 1,
      unit: 'month',
      aggregatedUnit: 'DAY',
      range: {
        start: new Date().setDate(new Date().getDate() - 30),
        end: new Date().getTime(),
      },
    },
    {
      label: 'Last Month',
      value: 'last_month',
      mode: 'period',
      aggregatedUnit: 'DAY',
      range: {
        start: new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).setHours(0, 0, 0, 0),
        end: new Date(new Date().setDate(0)).setHours(23, 59, 59),
      },
    },
    {
      label: 'Last 7 Days',
      value: 'last_days_7',
      mode: 'last',
      duration: 1,
      unit: 'week',
      aggregatedUnit: 'DAY',
      range: {
        start: new Date().setDate(new Date().getDate() - 7),
        end: new Date().getTime(),
      },
    },
    {
      label: 'Last Week',
      value: 'last_week',
      mode: 'period',
      aggregatedUnit: 'DAY',
      range: {
        start: lastWeek().monday.getTime(),
        end: lastWeek().sunday.getTime(),
      },
    },
    {
      label: 'Today',
      value: 'today',
      mode: 'period',
      aggregatedUnit: 'MINUTE',
      range: {
        start: new Date().setHours(0, 0, 0, 0),
        end: new Date().getTime(),
      },
    },
    {
      label: 'Last Day',
      value: 'last_day',
      mode: 'last',
      duration: 1,
      unit: 'day',
      aggregatedUnit: 'MINUTE',
      // range: {
      //   start: new Date().setHours(0, 0, 0, 0),
      //   end: new Date().getTime(),
      // },
    },
    {
      label: 'Custom',
      value: 'custom',
      mode: 'period',
      selectable: false,
    },
  ];
}

function getIntervalObject() {
  const intervalsObject = {};
  getIntervals().forEach((interval) => {
    intervalsObject[interval.value] = interval;
  });
  return intervalsObject;
}

const dataIntervalOptions = [
  {
    label: 'By Minute',
    value: 'MINUTE',
    seconds: 60,
  },
  {
    label: 'Hourly',
    value: 'HOUR',
    seconds: 3600,
  },
  {
    label: 'Daily',
    value: 'DAY',
    seconds: 86400,
  },
  {
    label: 'Weekly',
    value: 'WEEK',
    seconds: 604800,
  },
  {
    label: 'Monthly',
    value: 'MONTH',
    seconds: 2592000,
  },
];

function getSeconds(timeUnit) {
  let seconds = 0;
  dataIntervalOptions.forEach((option) => {
    if (option.value === timeUnit) {
      // console.log(timeUnit, option.value);
      seconds = option.seconds;
    }
  });
  return seconds;
}

export default {
  byInterval(interval) {
    return getIntervalObject()[interval];
  },
  intervals: getIntervals(),
  dataIntervalOptions,
  msToS(intervalObject) {
    // console.log('Object to alter : ', intervalObject);
    const alteredIntervalObject = JSON.parse(JSON.stringify(intervalObject));
    if (intervalObject.range && intervalObject.range.start && intervalObject.range.end) {
      alteredIntervalObject.range.start = parseInt(intervalObject.range.start / 1000);
      alteredIntervalObject.range.end = parseInt(intervalObject.range.end / 1000);
    }
    return alteredIntervalObject;
  },
  queryToDateParam(query) {
    // console.log('Convert : ', query);
    const selectedParam = getIntervalObject()[query.value];
    if (query.start && query.end) {
      selectedParam.range = {
        start: parseInt(query.start),
        end: parseInt(query.end),
      };
    }
    selectedParam.aggregatedUnit = query.aggregatedUnit;
    if (query.timezone) selectedParam.timezone = query.timezone;
    return selectedParam;
  },
  dateParamToQuery(date) {
    const query = {};
    if (date.mode) query.mode = date.mode;
    if (date.duration) query.duration = date.duration;
    if (date.range && date.range.start) query.start = date.range.start;
    if (date.range && date.range.end) query.end = date.range.end;
    if (date.unit) query.unit = date.unit;
    if (date.value) query.value = date.value;
    if (date.aggregatedUnit) query.aggregatedUnit = date.aggregatedUnit;
    return query;
  },
  toLocalDate(timeInSec) {
    const datetime = timeInSec * 1000;
    const a = new Date(datetime);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate().toString().padStart(2, '0');
    const hour = a.getHours().toString().padStart(2, '0');
    const min = a.getMinutes().toString().padStart(2, '0');
    const sec = a.getSeconds().toString().padStart(2, '0');
    const time = `${date} ${month} ${year} ${hour}:${min}:${sec}`;
    return time;
  },
  autoMaxData(dateParam) {
    // console.log('Date to max : ', dateParam.mode);
    let secondsLength = 86400;
    if (dateParam.range) {
      secondsLength = dateParam.range.end - dateParam.range.start;
    }
    const unitInSeconds = getSeconds(dateParam.aggregatedUnit);
    const expectedDataLength = secondsLength / unitInSeconds;
    const maxDataLimit = 50;
    if (!dateParam.timezone) {
      dateParam.timezone = (new Date().getTimezoneOffset() / 60) * -1;
    }
    if (expectedDataLength > maxDataLimit) {
      dateParam.aggregatedLength = parseInt(expectedDataLength / maxDataLimit);
      console.log('Exceed data length...', expectedDataLength, dateParam.aggregatedLength);
    }
    // console.log('Limit this...', secondsLength, unitInSeconds, expectedDataLength);
    return dateParam;
  },
};

export function convertDateToStandard(date) {
  // return new Date(date).toISOString();
  // const dateDate = new Date();
  // const gmt = (dateDate.getTimezoneOffset() / 60) * -1;
  // let gmtText = '';
  // if (gmt === 0) {
  //   //
  // } else if (gmt > 0) {
  //   gmtText = `+${gmt}`;
  // } else {
  //   gmtText = `${gmt}`;
  // }
  console.info('convertDateToStandard', date);
  return `${moment(date).format('DD MMMM yyyy HH:mm:ss')} GMT ${moment(date).format('ZZ')}`;
}

export function toHHMMSS(seconds) {
  if (!seconds) {
    return '00:00:00';
  }
  const SECONDS_PER_DAY = 86400;
  const HOURS_PER_DAY = 24;
  const days = Math.floor(seconds / SECONDS_PER_DAY);
  const remainderSeconds = seconds % SECONDS_PER_DAY;
  const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
  return hms.replace(/^(\d+)/, (h) => `${parseInt(h) + days * HOURS_PER_DAY}`.padStart(2, '0'));
}

export function generateTimestampsInSeconds(start, end, intervalInSeconds) {
  const timestampsInSeconds = [];
  let currentTimestamp = start;

  while (currentTimestamp <= end) {
    timestampsInSeconds.push(currentTimestamp);
    currentTimestamp += intervalInSeconds;
  }

  return timestampsInSeconds;
}

export function generateHourArray(startDate, endDate) {
  const start = startDate.getHours();
  const end = endDate.getHours();
  const diff = endDate.getDate() - startDate.getDate();
  const hours = [];

  if (start > 23 || end > 23) {
    throw new Error('Invalid hour input. Hours must be between 0 and 23.');
  }

  for (let i = start; i <= 23; i++) {
    hours.push(i === 0 ? 24 : i);
  }

  if (diff > 0) {
    for (let l = 0; l <= end; l++) {
      hours.push(l === 0 ? 24 : l);
    }
  }

  return hours;
}
