<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="mt-3">
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-table id="tableLogs" selectable sticky-header="300px" striped hover fixed small @row-selected="rowSelected" :items="series" :fields="fields" class="bg-white text-center table-logs" v-if="show">
          <!-- <b-table responsive sticky-header="150px" striped :items="series" :fields="fields" v-if="show"> -->
            <template #thead-top="data">
              <!-- <b-tr class="text-center">
                <b-th colspan="1"></b-th>
                <b-th colspan="4">GPS</b-th>
                <b-th colspan="4">RPM</b-th>
                <b-th colspan="6">AE</b-th>
                <b-th colspan="17">Flowmeter</b-th>
              </b-tr> -->
              <!-- <b-tr class="text-center">
                <b-th colspan="2">Location</b-th>
                <b-th colspan="2">Movement</b-th>
                <b-th colspan="3">ME Port</b-th>
                <b-th colspan="3">ME Stbd</b-th>
                <b-th colspan="2">ME Port</b-th>
                <b-th colspan="2">ME Stbd</b-th>
                <b-th colspan="2">AE1</b-th>
                <b-th colspan="2">AE2</b-th>
                <b-th colspan="2">AE3</b-th>
                <b-th colspan="2">AE4</b-th>
              </b-tr> -->
            </template>
            <template #cell(datetime)="data">
              {{ data.item.timestamp | toLocalDate }}
            </template>
            <template #cell(coordinate)="data">
              <span v-if="data.item.gps && data.item.gps.latitude && data.item.gps.longitude">{{ data.item.gps.latitude | latToDMS }}, {{ data.item.gps.longitude | latToDMS }}</span>
            </template>
            <template #cell(track)="data">
              <span v-if="data.item.gps && data.item.gps.track !== undefined">{{ data.item.gps.track.toFixed() }}</span>
            </template>

            <template #head(speed)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Knot</small></div>
            </template>
            <template #cell(speed)="data">
              <span v-if="data.item.gps">{{ data.item.gps.additional.AVG_dlgpSpeed_0 | toKnot }}</span>
            </template>

            <template #head(distance)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>KM</small></div>
            </template>
            <template #cell(distance)="data">
              <span v-if="data.item.gps">{{ data.item.gps.additional.SUM_dlgpDistance_3 | toKM }}</span>
            </template>

            <template #cell(portRPM)="data">
              <span v-if="data.item && data.item.rpm && data.item.rpm.PORT && data.item.rpm.PORT.rpm !== undefined">{{ data.item.rpm.PORT.rpm.toFixed(2) }}</span>
              <span v-else>0</span>
            </template>
            <template #cell(portRunningTime)="data">
              <span v-if="data.item && data.item.rpm && data.item.rpm.PORT">{{ data.item.rpm.PORT.runningTime | toHHMMSS }}</span>
              <span v-else>00:00:00</span>
            </template>

            <template #cell(meRPM)="data">
              <span v-if="data.item && data.item.rpm && data.item.rpm.MAINENGINE && data.item.rpm.MAINENGINE.rpm !== undefined">{{ data.item.rpm.MAINENGINE.rpm.toFixed(2) }}</span>
              <span v-else>0</span>
            </template>
            <template #cell(meRunningTime)="data">
              <span v-if="data.item && data.item.rpm && data.item.rpm.MAINENGINE">{{ data.item.rpm.MAINENGINE.runningTime | toHHMMSS }}</span>
              <span v-else>00:00:00</span>
            </template>
            <!-- <template #cell(portRunningTime)="data">
              <span>{{ data.item.PORT.runningTime | toHHMMSS}}</span>
            </template> -->
            <template #cell(stbRPM)="data">
              <span v-if="data.item && data.item.rpm && data.item.rpm.STARBOARD && data.item.rpm.STARBOARD.rpm !== undefined">{{ data.item.rpm.STARBOARD.rpm.toFixed(2) }}</span>
              <span v-else>0</span>
            </template>
            <template #cell(stbRunningTime)="data">
              <span v-if="data.item && data.item.rpm && data.item.rpm.STARBOARD">{{ data.item.rpm.STARBOARD.runningTime | toHHMMSS }}</span>
              <span v-else>00:00:00</span>
            </template>
            <!-- <template #cell(stbRunningTime)="data">
              <span>{{ data.item.STARBOARD.runningTime | toHHMMSS}}</span>
            </template> -->
            <template #head(AE1)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>HH:MM:SS</small></div>
            </template>
            <template #cell(AE1)="data">
              <span v-if="data.item.ae&&data.item.ae.AE1">{{ data.item.ae.AE1.runningSeconds | toHHMMSS }}</span>
            </template>
            <template #head(AE2)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>HH:MM:SS</small></div>
            </template>
            <template #cell(AE2)="data">
              <span v-if="data.item.ae&&data.item.ae.AE2">{{ data.item.ae.AE2.runningSeconds | toHHMMSS }}</span>
            </template>
            <template #head(AE3)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>HH:MM:SS</small></div>
            </template>
            <template #cell(AE3)="data">
              <span v-if="data.item.ae&&data.item.ae.AE3">{{ data.item.ae.AE3.runningSeconds | toHHMMSS }}</span>
            </template>
            <template #head(AE4)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>HH:MM:SS</small></div>
            </template>
            <template #cell(AE4)="data">
              <span v-if="data.item.ae&&data.item.ae.AE4">{{ data.item.ae.AE4.runningSeconds | toHHMMSS }}</span>
            </template>

            <template #head(AE1FC)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #head(AE2FC)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #head(AE3FC)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #head(AE4FC)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #head(AEFC)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #cell(AE1FC)="data">
              <span v-if="data.item.ae && data.item.ae.AE1 && data.item.ae.AE1.fuelConsumption !== undefined">{{ (data.item.ae.AE1.fuelConsumption).toFixed(2) }}</span>
            </template>
            <template #cell(AE2FC)="data">
              <span v-if="data.item.ae && data.item.ae.AE2 && data.item.ae.AE2.fuelConsumption !== undefined">{{ (data.item.ae.AE2.fuelConsumption).toFixed(2) }}</span>
            </template>
            <template #cell(AE3FC)="data">
              <span v-if="data.item.ae && data.item.ae.AE3 && data.item.ae.AE3.fuelConsumption !== undefined">{{ (data.item.ae.AE3.fuelConsumption).toFixed(2) }}</span>
            </template>
            <template #cell(AE4FC)="data">
              <span v-if="data.item.ae && data.item.ae.AE4 && data.item.ae.AE4.fuelConsumption !== undefined">{{ (data.item.ae.AE4.fuelConsumption).toFixed(2) }}</span>
            </template>
            <template #cell(AEFC)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.AE_IN && data.item.flowmeter.AE_OUT && data.item.flowmeter.aeFuelCons !== undefined">{{ (data.item.flowmeter.aeFuelCons).toFixed(2) }}</span>
            </template>

            <template #head(portFuelCons)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #cell(portFuelCons)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.portFuelCons !== undefined">{{ data.item.flowmeter.portFuelCons.toFixed(2) }}</span>
            </template>

            <template #head(stbFuelCons)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #cell(stbFuelCons)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.stbFuelCons !== undefined">{{ data.item.flowmeter.stbFuelCons.toFixed(2) }}</span>
            </template>

            <template #head(stbFuelRate)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>L/h</small></div>
            </template>
            <template #cell(stbFuelRate)="data">
              <span v-if="data.item && data.item.flowmeter && data.item.flowmeter.stbFuelCons && data.item.rpm && data.item.rpm.STARBOARD && data.item.rpm.STARBOARD.runningTime">{{ (data.item.flowmeter.stbFuelCons / (3600 / data.item.rpm.STARBOARD.runningTime)).toFixed(2) }}</span>
              <span v-else>0.00</span>
            </template>
            <template #head(portFuelRate)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>L/h</small></div>
            </template>
            <template #cell(portFuelRate)="data">
              <span v-if="data.item && data.item.flowmeter && data.item.flowmeter.portFuelCons && data.item.rpm && data.item.rpm.PORT && data.item.rpm.PORT.runningTime">{{ (data.item.flowmeter.portFuelCons / (3600 / data.item.rpm.PORT.runningTime)).toFixed(2) }}</span>
              <span v-else>0.00</span>
            </template>

            <template #head(portInFlow)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #cell(portInFlow)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.portInFlow !== undefined">{{ data.item.flowmeter.portInFlow.toFixed(2) }}</span>
            </template>

            <template #head(portInTemp)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>°C</small></div>
            </template>
            <template #cell(portInTemp)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.PORT_IN && data.item.flowmeter.PORT_IN.temperature !== undefined">{{ data.item.flowmeter.PORT_IN.temperature.toFixed(2) }}</span>
            </template>
            <template #head(portOutTemp)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>°C</small></div>
            </template>
            <template #cell(portOutTemp)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.PORT_OUT && data.item.flowmeter.PORT_OUT.temperature !== undefined">{{ data.item.flowmeter.PORT_OUT.temperature.toFixed(2) }}</span>
              <span v-else>0.00</span>
            </template>

            <template #head(stbInTemp)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>°C</small></div>
            </template>
            <template #cell(stbInTemp)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.STARBOARD_IN && data.item.flowmeter.STARBOARD_IN.temperature !== undefined">{{ data.item.flowmeter.STARBOARD_IN.temperature.toFixed(2) }}</span>
            </template>
            <template #head(stbOutTemp)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>°C</small></div>
            </template>
            <template #cell(stbOutTemp)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.STARBOARD_OUT && data.item.flowmeter.STARBOARD_OUT.temperature !== undefined">{{ data.item.flowmeter.STARBOARD_OUT.temperature.toFixed(2) }}</span>
              <span v-else>0.00</span>
            </template>

            <template #head(portInDens)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>kg/m<sup>3</sup></small></div>
            </template>
            <template #cell(portInDens)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.portInDens !== undefined">{{ data.item.flowmeter.portInDens.toFixed(2) }}</span>
            </template>

            <template #head(portOutFlow)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #cell(portOutFlow)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.portOutFlow !== undefined">{{ data.item.flowmeter.portOutFlow.toFixed(2) }}</span>
            </template>

            <template #head(portOutDens)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>kg/m<sup>3</sup></small></div>
            </template>
            <template #cell(portOutDens)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.portOutDens !== undefined">{{ data.item.flowmeter.portOutDens.toFixed(2) }}</span>
            </template>

            <template #head(stbInFlow)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #cell(stbInFlow)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.stbInFlow !== undefined">{{ data.item.flowmeter.stbInFlow.toFixed(2) }}</span>
            </template>

            <template #head(stbInDens)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>kg/m<sup>3</sup></small></div>
            </template>
            <template #cell(stbInDens)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.stbInDens !== undefined">{{ data.item.flowmeter.stbInDens.toFixed(2) }}</span>
            </template>
            <template #head(stbOutFlow)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #cell(stbOutFlow)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.stbOutFlow !== undefined">{{ data.item.flowmeter.stbOutFlow.toFixed(2) }}</span>
            </template>
            <template #head(stbOutDens)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>kg/m<sup>3</sup></small></div>
            </template>
            <template #cell(stbOutDens)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.stbOutDens !== undefined">{{ data.item.flowmeter.stbOutDens.toFixed(2) }}</span>
            </template>
            <template #head(meFuelCons)="data">
              <div class="text-nowrap">
                {{ data.label }}
              </div>
              <div><small>Liter</small></div>
            </template>
            <template #cell(meFuelCons)="data">
              <span v-if="data.item.flowmeter && data.item.flowmeter.meFuelCons !== undefined">{{ data.item.flowmeter.meFuelCons.toFixed(2) }}</span>
            </template>
          </b-table>
          <b-alert v-else show variant="danger">No Data</b-alert>
        </div>
        <!-- <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          pills
          size="sm"
        ></b-pagination> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReportTable',
  props: ['data'],
  data() {
    return {
      // ready: false,
      // currentPage: 1,
      // perPage: 5,
      // totalRows: 10,
      // filter: {},
      // selected: {},
      series: [],
      // dataIndex: {},
      fields: [],
      show: undefined,
    };
  },
  methods: {
    rowSelected(selected) {
      if (selected.length === 2) {
        this.$emit('tableRangeSelected', {
          start: selected[0].timestamp,
          end: selected[1].timestamp,
        });
        // console.log('Selected... ', selected[0].timestamp, selected[1].timestamp);
      }
    },
    parseData() {
      Object.keys(this.data.datas).forEach((timestamp) => {
        const serie = this.data.datas[timestamp];
        serie.timestamp = parseInt(timestamp);
        this.series.push(serie);
        if (!this.show) {
          this.show = {};
          Object.keys(serie).forEach((device) => {
            console.log('device>>>', device);
            this.show[device] = true;
          });
        }
        // console.log(serie);
      });
    },
    parseFields() {
      this.fields.push(
        {
          key: 'datetime',
          label: 'Date & Time',
          stickyColumn: true,
        },
      );
      if (this.show && this.show.gps) {
        this.fields.push(
          {
            key: 'coordinate',
            label: 'Coordinate',
          },
          {
            key: 'track',
            label: 'Heading',
          },
          {
            key: 'speed',
            label: 'Speed',
          },
          {
            key: 'distance',
            label: 'Distance',
          },
        );
      }
      if (this.show && this.show.rpm) {
        if (this.data.summaries.rpm.singleEngine) {
          this.fields.push(
            {
              key: 'meRPM',
              label: 'ME RPM',
            },
            {
              key: 'meRunningTime',
              label: 'ME Run Hours',
            },
          );
        } else {
          this.fields.push(
            {
              key: 'portRPM',
              label: 'Port RPM',
            },
            {
              key: 'portRunningTime',
              label: 'Port Run Hours',
            },
            {
              key: 'stbRPM',
              label: 'Stb RPM',
            },
            {
              key: 'stbRunningTime',
              label: 'Stb Run Hours',
            },
          );
        }
      }
      if (this.show && this.show.flowmeter) {
        if (this.data.summaries.rpm.singleEngine) {
          this.fields.push(
            {
              key: 'meFuelCons',
              label: 'ME Cons',
            },
            {
              key: 'portInFlow',
              label: 'ME In Flow',
            },
            {
              key: 'portInTemp',
              label: 'ME In Temp',
            },
            {
              key: 'portOutFlow',
              label: 'ME Out Flow',
            },
            {
              key: 'portOutTemp',
              label: 'ME Out Temp',
            },
            {
              key: 'portInDens',
              label: 'ME In Density',
            },
            {
              key: 'portOutDens',
              label: 'ME Out Density',
            },
          );
        } else {
          this.fields.push(
            {
              key: 'portFuelCons',
              label: 'Port Fuel Cons',
            },
            {
              key: 'portFuelRate',
              label: 'Port Fuel Rate',
            },
            {
              key: 'portInFlow',
              label: 'Port In Flow',
            },
            {
              key: 'portInTemp',
              label: 'Port In Temp',
            },
            {
              key: 'portOutFlow',
              label: 'Port Out Flow',
            },
            {
              key: 'portOutTemp',
              label: 'Port Out Temp',
            },
            {
              key: 'portInDens',
              label: 'Port In Density',
            },
            {
              key: 'portOutDens',
              label: 'Port Out Density',
            },
            {
              key: 'stbFuelCons',
              label: 'Stb Fuel Cons',
            },
            {
              key: 'stbFuelRate',
              label: 'Stb Fuel Rate',
            },
            {
              key: 'stbInFlow',
              label: 'Stb In Flow',
            },
            {
              key: 'stbInTemp',
              label: 'Stb In Temp',
            },
            {
              key: 'stbOutFlow',
              label: 'Stb Out Flow',
            },
            {
              key: 'stbOutTemp',
              label: 'Stb Out Temp',
            },
            {
              key: 'stbInDens',
              label: 'Stb In Density',
            },
            {
              key: 'stbOutDens',
              label: 'Stb Out Density',
            },
            {
              key: 'meFuelCons',
              label: 'ME Cons',
            },
          );
        }
      }
      if (this.show && this.show.ae) {
        if (this.data.summaries.rpm.singleEngine) {
          this.fields.push(
            {
              key: 'AE1',
              label: 'AE1 Run Hr',
            },
            {
              key: 'AE2',
              label: 'AE2 Run Hr',
            },
            {
              key: 'AE3',
              label: 'AE3 Run Hr',
            },
          );
        } else if (this.data.summaries.flowmeter.aeEngineCons) {
          this.fields.push(
            {
              key: 'AE1',
              label: 'AE1 Run Hr',
            },
            {
              key: 'AE2',
              label: 'AE2 Run Hr',
            },
            {
              key: 'AE3',
              label: 'AE3 Run Hr',
            },
            {
              key: 'AEFC',
              label: 'AE Fuel Cons',
            },
          );
        } else {
          this.fields.push(
            {
              key: 'AE1',
              label: 'AE1 Run Hr',
            },
            {
              key: 'AE1FC',
              label: 'AE1 Fuel Cons',
            },
            {
              key: 'AE2',
              label: 'AE2 Run Hr',
            },
            {
              key: 'AE2FC',
              label: 'AE2 Fuel Cons',
            },
            {
              key: 'AE3',
              label: 'AE3 Run Hr',
            },
            {
              key: 'AE3FC',
              label: 'AE3 Fuel Cons',
            },
          );
        }
      }
    },
  },
  mounted() {
    // this.series = this.data.datas;
    // console.log('ABC DEF');
    console.log('data>>>>', this.data);
    this.parseData();
    this.parseFields();
    // await this.getSeries();
    // const response = await SeriesSvc.getSeriesByInterval('day', 30, [deviceId]);
  },
  // computed: {
  //   fields() {
  //     return [
  //       {
  //         key: 'portFuelCons',
  //         label: 'Port Fuel Cons',
  //       },
  //       {
  //         key: 'portInFlow',
  //         label: 'Port In Flow',
  //       },
  //       {
  //         key: 'portOutFlow',
  //         label: 'Port Out Flow',
  //       },
  //       {
  //         key: 'portInDens',
  //         label: 'Port In Density',
  //       },
  //       {
  //         key: 'portOutDens',
  //         label: 'Port Out Density',
  //       },
  //       {
  //         key: 'stbFuelCons',
  //         label: 'Stb Fuel Cons',
  //       },
  //       {
  //         key: 'stbInFlow',
  //         label: 'Stb In Flow',
  //       },
  //       {
  //         key: 'stbOutFlow',
  //         label: 'Stb Out Flow',
  //       },
  //       {
  //         key: 'stbInDens',
  //         label: 'Stb In Density',
  //       },
  //       {
  //         key: 'stbOutDens',
  //         label: 'Stb Out Density',
  //       },
  //       {
  //         key: 'meFuelCons',
  //         label: 'ME Cons',
  //       },
  //     ];
  //   },
  // },
};
</script>

<style scoped lang="scss">
.table-logs {
  display: block;
  font-size: 11px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
