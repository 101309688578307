/*
  /series/aggregated/{DEVICETYPE}/last/6/month
  /series/aggregated/{DEVICETYPE}/period/{START_TIMESTAMP}/{END_TIMESTAMP}

  DEVICETYPE = ae|rpm|gateway|flowmeter|power|gps

  GET parameter :
  device[] : required = device ID. multiple
  no[] : optional = for device type that has many value for the same device id (for now : ae, rpm)
  type[] optional = for device type that has defferent type for the same device id ( for now : rpm) : STARBOARD|PORT
  aggregatedUnit : optional : default MINUTE (upper case) = unit for aggregating data per time frame
  aggregatedLength : optional : default 1 = value for aggregating data per time frame
  primaryFunctionName : optional : default FIRST (upper case) = selected data for each time frame = FIRST|LAST

  For the following parameter please read MySQL 8.0 docs for window function to use. The value will replace the value for field in functionParams[][0]
  functionNames[] : optional = AVG|SUM|MIN|MAX . multiple
  functionParams[] : optional = parameter for functionNames function. multiple. To use specific field change field prefix with @. deloRawData -> @rawData
  partitionBy[][] : optional  = group by definition for functionNames. multiple multiple. Automatically added with no & type if applicable for certain device type
  partitionOrder[][] : optional = order by definition for functionNames. multiple multiple. Automatically added with no & type if applicable for certain device type

  Filtering feature
  &additionalFIlter[]=@satellites|24|gte&filter[]=@altitude|12|LT
  value nya = field|value|operation
  field : pake @ kalo mau ambil nama field dr table terkait
  operation : EQ, NE, LT, GT, LTE, GTE

  examples:
  /series/aggregated/rpm/last/6/month?device[]=357660090106550&device[]=357660090097999&device[]=rpm-1&aggregatedUnit=MINUTE&functionNames[]=AVG&functionParams[]=@Rpm
  series/aggregated/flowmeter/last/6/month?device[]=357660090106550&device[]=357660090097999&device[]=fm-1&aggregatedUnit=HOUR&functionNames[1]=AVG&functionParams[1]=@density&functionNames[2]=SUM&functionParams[2]=@temp

  {
    type: 'last',
    duration: 4,
    unit: 'month',
    start: timestamp,
    end: timestamp,
    params
  }

  ----------------------------=||=----------------------------

  /series/device/{TYPE}/pulse

  param:
  - device[] : mandatory, multiple
  - type[] : optional (if applicable, for now just for rpm), multiple
  - no[] : optional (if applicable, for now rpm & ae), multiple

  ----------------------------=||=----------------------------

*/

import axios from 'axios';
import LatLon from 'geodesy/latlon-spherical.js';
import assetConfig from '../config/asset';

let isSingleEngine = false;
// const doTimingCorrection = false; // HS
const singleEngineIds = ['fm-mdm_medan1-in', 'fm-k09-1', 'rpm-m20', 'rpm-turaco-316'];

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.data && error.response.data.status && error.response.data.status.errorCode === 411) {
    console.log('Logged out from system ... ', error.response.data.status.errorCode);
    window.location.href = window.location.origin;
  }
  return Promise.reject(error);
});

// function msToSec(ms) {
//   return (ms / 1000).toFixed(0);
// }

function round(x, multiplication) {
  if (x % multiplication === 0) {
    return parseInt(Math.floor(x / multiplication)) * multiplication;
  }
  return (parseInt(Math.floor(x / multiplication)) * multiplication);
}

function constructFlowMeterData(dataSeries, devices) {
  // console.log('Construct flowmeter data series... ', this);
  const devicesObject = {};
  devices.forEach((device) => {
    devicesObject[device.devcUniqueId] = device;
  });
  const timestampIndex = {};
  const flowmeterSummary = {
    interval: dataSeries.interval,
    start: dataSeries.from,
    end: dataSeries.to,
  };
  const firstFlowMeterData = {};
  const lastFlowMeterData = {};
  let portInIndex = 0;
  let portOutIndex = 0;
  let starboardInIndex = 0;
  let starboardOutIndex = 0;
  let aeInIndex = 0;
  let aeOutIndex = 0;
  let portInMin = 0;
  let portOutMin = 0;
  let starboardInMin = 0;
  let starboardOutMin = 0;
  let aeInMin = 0;
  let aeOutMin = 0;
  let totalPortFlowrate = 0;
  let totalStbFlowrate = 0;
  dataSeries.series.forEach((serie) => {
    console.log('flowmeter-dedevices', serie.deviceId);
    if (singleEngineIds.includes(serie.deviceId)) {
      isSingleEngine = true;
    }
    // do timing correction
    // if (doTimingCorrection) { // HS
    const timeToMin = round(serie.timestamp, dataSeries.interval);
    if ((serie.timestamp - timeToMin) !== 0 && (serie.timestamp - timeToMin) < dataSeries.interval) {
      serie.timestamp -= serie.timestamp - timeToMin;
    }
    // } // HS
  });
  dataSeries.series.forEach((serie) => {
    if (!flowmeterSummary[devicesObject[serie.deviceId].devcLabel]) flowmeterSummary[devicesObject[serie.deviceId].devcLabel] = { _averageFlowRate: 0, _dataFreq: 0 };
    // console.log(serie);
    // flowmeter misconfiguration temporary fix
    if (serie.deviceId === 'fm-mdm_medan1-in') {
      // serie.additional.AVG_dlfmVolFlowrate_2 *= 60;
      // serie.volumeFlowrate *= 60;
    }
    if (serie.additional.AVG_dlfmVolFlowrate_2) flowmeterSummary[devicesObject[serie.deviceId].devcLabel]._averageFlowRate += serie.additional.AVG_dlfmVolFlowrate_2;
    if (serie.additional.AVG_dlfmVolFlowrate_2 > 0) {
      flowmeterSummary[devicesObject[serie.deviceId].devcLabel]._dataFreq++;
    }
    // console.log('S : ', flowmeterSummary, serie);
    if (!timestampIndex[serie.timestamp]) timestampIndex[serie.timestamp] = {};
    if (!timestampIndex[serie.timestamp][devicesObject[serie.deviceId].devcLabel]) timestampIndex[serie.timestamp][devicesObject[serie.deviceId].devcLabel] = serie;
    if (Object.keys(timestampIndex[serie.timestamp]).length === dataSeries.devices.length) {
      // PORT
      if (timestampIndex[serie.timestamp].PORT_IN && timestampIndex[serie.timestamp].PORT_IN.additional) {
        if (dataSeries.interval === 60 || portInIndex === 0) {
          timestampIndex[serie.timestamp].PORT_IN.volumeFlow = timestampIndex[serie.timestamp].PORT_IN.additional.MAX_dlfmVolInventory_1 - timestampIndex[serie.timestamp].PORT_IN.additional.MIN_dlfmVolInventory_0;
        } else {
          timestampIndex[serie.timestamp].PORT_IN.volumeFlow = timestampIndex[serie.timestamp].PORT_IN.additional.MAX_dlfmVolInventory_1 - portInMin;
        }
        totalPortFlowrate += (timestampIndex[serie.timestamp].PORT_IN.additional.SUM_dlfmVolFlowrate_5 / 60);
        portInIndex++;
        portInMin = timestampIndex[serie.timestamp].PORT_IN.additional.MAX_dlfmVolInventory_1;
      }
      if (timestampIndex[serie.timestamp].PORT_OUT && timestampIndex[serie.timestamp].PORT_OUT.additional) {
        if (dataSeries.interval === 60 || portOutIndex === 0) {
          timestampIndex[serie.timestamp].PORT_OUT.volumeFlow = timestampIndex[serie.timestamp].PORT_OUT.additional.MAX_dlfmVolInventory_1 - timestampIndex[serie.timestamp].PORT_OUT.additional.MIN_dlfmVolInventory_0;
        } else {
          timestampIndex[serie.timestamp].PORT_OUT.volumeFlow = timestampIndex[serie.timestamp].PORT_OUT.additional.MAX_dlfmVolInventory_1 - portOutMin;
        }
        portOutIndex++;
        portOutMin = timestampIndex[serie.timestamp].PORT_OUT.additional.MAX_dlfmVolInventory_1;
      }
      // STARBOARD
      if (timestampIndex[serie.timestamp].STARBOARD_IN && timestampIndex[serie.timestamp].STARBOARD_IN.additional) {
        if (dataSeries.interval === 60 || starboardInIndex === 0) {
          timestampIndex[serie.timestamp].STARBOARD_IN.volumeFlow = timestampIndex[serie.timestamp].STARBOARD_IN.additional.MAX_dlfmVolInventory_1 - timestampIndex[serie.timestamp].STARBOARD_IN.additional.MIN_dlfmVolInventory_0;
        } else {
          timestampIndex[serie.timestamp].STARBOARD_IN.volumeFlow = timestampIndex[serie.timestamp].STARBOARD_IN.additional.MAX_dlfmVolInventory_1 - starboardInMin;
        }
        totalStbFlowrate += (timestampIndex[serie.timestamp].STARBOARD_IN.additional.SUM_dlfmVolFlowrate_5 / 60);
        starboardInIndex++;
        starboardInMin = timestampIndex[serie.timestamp].STARBOARD_IN.additional.MAX_dlfmVolInventory_1;
      }
      if (timestampIndex[serie.timestamp].STARBOARD_OUT && timestampIndex[serie.timestamp].STARBOARD_OUT.additional) {
        if (dataSeries.interval === 60 || starboardOutIndex === 0) {
          timestampIndex[serie.timestamp].STARBOARD_OUT.volumeFlow = timestampIndex[serie.timestamp].STARBOARD_OUT.additional.MAX_dlfmVolInventory_1 - timestampIndex[serie.timestamp].STARBOARD_OUT.additional.MIN_dlfmVolInventory_0;
        } else {
          timestampIndex[serie.timestamp].STARBOARD_OUT.volumeFlow = timestampIndex[serie.timestamp].STARBOARD_OUT.additional.MAX_dlfmVolInventory_1 - starboardOutMin;
        }
        starboardOutIndex++;
        starboardOutMin = timestampIndex[serie.timestamp].STARBOARD_OUT.additional.MAX_dlfmVolInventory_1;
      }
      // AE
      if (timestampIndex[serie.timestamp].AE_IN && timestampIndex[serie.timestamp].AE_IN.additional) {
        if (dataSeries.interval === 60 || aeInIndex === 0) {
          timestampIndex[serie.timestamp].AE_IN.volumeFlow = timestampIndex[serie.timestamp].AE_IN.additional.MAX_dlfmVolInventory_1 - timestampIndex[serie.timestamp].AE_IN.additional.MIN_dlfmVolInventory_0;
        } else {
          timestampIndex[serie.timestamp].AE_IN.volumeFlow = timestampIndex[serie.timestamp].AE_IN.additional.MAX_dlfmVolInventory_1 - aeInMin;
        }
        aeInIndex++;
        aeInMin = timestampIndex[serie.timestamp].AE_IN.additional.MAX_dlfmVolInventory_1;
      }
      if (timestampIndex[serie.timestamp].AE_OUT && timestampIndex[serie.timestamp].AE_OUT.additional) {
        if (dataSeries.interval === 60 || aeOutIndex === 0) {
          timestampIndex[serie.timestamp].AE_OUT.volumeFlow = timestampIndex[serie.timestamp].AE_OUT.additional.MAX_dlfmVolInventory_1 - timestampIndex[serie.timestamp].AE_OUT.additional.MIN_dlfmVolInventory_0;
        } else {
          timestampIndex[serie.timestamp].AE_OUT.volumeFlow = timestampIndex[serie.timestamp].AE_OUT.additional.MAX_dlfmVolInventory_1 - aeOutMin;
        }
        aeOutIndex++;
        aeOutMin = timestampIndex[serie.timestamp].AE_OUT.additional.MAX_dlfmVolInventory_1;
      }
      // PORT
      if (timestampIndex[serie.timestamp].PORT_IN && timestampIndex[serie.timestamp].PORT_OUT) {
        if (timestampIndex[serie.timestamp].PORT_IN.volumeFlow !== undefined && timestampIndex[serie.timestamp].PORT_OUT.volumeFlow !== undefined) {
          timestampIndex[serie.timestamp].portFuelCons = timestampIndex[serie.timestamp].PORT_IN.volumeFlow - timestampIndex[serie.timestamp].PORT_OUT.volumeFlow;
        }
        if (timestampIndex[serie.timestamp].PORT_IN.volumeFlowrate !== 0) {
          // console.log(timestampIndex[serie.timestamp].PORT_IN.deviceId === 'fm-mdm_medan1-in');
          timestampIndex[serie.timestamp].portInFlow = timestampIndex[serie.timestamp].PORT_IN.volumeFlowrate;
          // if (timestampIndex[serie.timestamp].PORT_IN.deviceId === 'fm-mdm_medan1-in') {
          //   timestampIndex[serie.timestamp].portInFlow = timestampIndex[serie.timestamp].PORT_IN.volumeFlowrate * 60;
          // }
        } else {
          timestampIndex[serie.timestamp].portInFlow = timestampIndex[serie.timestamp].PORT_IN.volumeFlow;
        }
        timestampIndex[serie.timestamp].portInDens = timestampIndex[serie.timestamp].PORT_IN.density;
        if (timestampIndex[serie.timestamp].PORT_OUT.volumeFlowrate !== 0) {
          timestampIndex[serie.timestamp].portOutFlow = timestampIndex[serie.timestamp].PORT_OUT.volumeFlowrate;
        } else {
          timestampIndex[serie.timestamp].portOutFlow = timestampIndex[serie.timestamp].PORT_OUT.volumeFlow;
        }
        timestampIndex[serie.timestamp].portOutDens = timestampIndex[serie.timestamp].PORT_OUT.density;
        // console.log('Is stuck here ?', timestampIndex[serie.timestamp]);
        timestampIndex[serie.timestamp].portFlow = timestampIndex[serie.timestamp].portInFlow - timestampIndex[serie.timestamp].portOutFlow;
      } else if (timestampIndex[serie.timestamp].PORT_IN && timestampIndex[serie.timestamp].PORT_OUT === undefined) {
        timestampIndex[serie.timestamp].portFuelCons = timestampIndex[serie.timestamp].PORT_IN.additional.SUM_dlfmVolFlowrate_5 / 60;
        if (timestampIndex[serie.timestamp].PORT_IN.additional.AVG_dlfmVolFlowrate_2 !== 0) {
          timestampIndex[serie.timestamp].portInFlow = timestampIndex[serie.timestamp].PORT_IN.additional.AVG_dlfmVolFlowrate_2;
        } else {
          timestampIndex[serie.timestamp].portInFlow = timestampIndex[serie.timestamp].PORT_IN.volumeFlow;
        }
        timestampIndex[serie.timestamp].portInDens = timestampIndex[serie.timestamp].PORT_IN.density;
        timestampIndex[serie.timestamp].portOutDens = 0;
        timestampIndex[serie.timestamp].portOutFlow = 0;
      }
      if ((timestampIndex[serie.timestamp].PORT_IN !== undefined && !firstFlowMeterData.PORT_IN) || timestampIndex[serie.timestamp].PORT_IN.timestamp < firstFlowMeterData.PORT_IN.timestamp) {
        firstFlowMeterData.PORT_IN = timestampIndex[serie.timestamp].PORT_IN;
        // console.log('PIN : ', firstFlowMeterData.PORT_IN);
      }
      // adjust
      if ((timestampIndex[serie.timestamp].PORT_OUT !== undefined && !firstFlowMeterData.PORT_OUT)) {
        firstFlowMeterData.PORT_OUT = timestampIndex[serie.timestamp].PORT_OUT;
        // console.log('POT : ', firstFlowMeterData.PORT_OUT);
      }

      if (timestampIndex[serie.timestamp].PORT_OUT && timestampIndex[serie.timestamp].PORT_OUT.timestamp < firstFlowMeterData.PORT_OUT.timestamp) {
        firstFlowMeterData.PORT_OUT = timestampIndex[serie.timestamp].PORT_OUT;
        // console.log('POT : ', firstFlowMeterData.PORT_OUT);
      }
      // PORT
      if (timestampIndex[serie.timestamp].PORT_IN !== undefined && !lastFlowMeterData.PORT_IN) {
        lastFlowMeterData.PORT_IN = timestampIndex[serie.timestamp].PORT_IN;
      }
      if (timestampIndex[serie.timestamp].PORT_IN.timestamp > lastFlowMeterData.PORT_IN.timestamp) {
        lastFlowMeterData.PORT_IN = timestampIndex[serie.timestamp].PORT_IN;
      }
      if (timestampIndex[serie.timestamp].PORT_OUT !== undefined || !lastFlowMeterData.PORT_OUT) {
        lastFlowMeterData.PORT_OUT = timestampIndex[serie.timestamp].PORT_OUT;
      }
      if (timestampIndex[serie.timestamp].PORT_OUT) {
        if (timestampIndex[serie.timestamp].PORT_OUT.timestamp > lastFlowMeterData.PORT_OUT.timestamp) {
          lastFlowMeterData.PORT_OUT = timestampIndex[serie.timestamp].PORT_OUT;
        }
      }
      // STARBOARD
      if (timestampIndex[serie.timestamp].STARBOARD_IN && timestampIndex[serie.timestamp].STARBOARD_OUT) {
        if (timestampIndex[serie.timestamp].STARBOARD_IN.volumeFlow !== undefined && timestampIndex[serie.timestamp].STARBOARD_OUT.volumeFlow !== undefined) {
          timestampIndex[serie.timestamp].stbFuelCons = timestampIndex[serie.timestamp].STARBOARD_IN.volumeFlow - timestampIndex[serie.timestamp].STARBOARD_OUT.volumeFlow;
        }
        if (timestampIndex[serie.timestamp].STARBOARD_IN.volumeFlowrate !== 0) {
          timestampIndex[serie.timestamp].stbInFlow = timestampIndex[serie.timestamp].STARBOARD_IN.volumeFlowrate;
        } else {
          timestampIndex[serie.timestamp].stbInFlow = timestampIndex[serie.timestamp].STARBOARD_IN.volumeFlow;
        }
        timestampIndex[serie.timestamp].stbInDens = timestampIndex[serie.timestamp].STARBOARD_IN.density;
        if (timestampIndex[serie.timestamp].STARBOARD_OUT.volumeFlowrate !== 0) {
          timestampIndex[serie.timestamp].stbOutFlow = timestampIndex[serie.timestamp].STARBOARD_OUT.volumeFlowrate;
        } else {
          timestampIndex[serie.timestamp].stbOutFlow = timestampIndex[serie.timestamp].STARBOARD_OUT.volumeFlow;
        }
        timestampIndex[serie.timestamp].stbOutDens = timestampIndex[serie.timestamp].STARBOARD_OUT.density;
        timestampIndex[serie.timestamp].stbFlow = timestampIndex[serie.timestamp].stbInFlow - timestampIndex[serie.timestamp].stbOutFlow;
      } else if (timestampIndex[serie.timestamp].STARBOARD_IN && timestampIndex[serie.timestamp].STARBOARD_OUT === undefined) {
        timestampIndex[serie.timestamp].stbFuelCons = timestampIndex[serie.timestamp].STARBOARD_IN.additional.SUM_dlfmVolFlowrate_5 / 60;
        if (timestampIndex[serie.timestamp].STARBOARD_IN.additional.AVG_dlfmVolFlowrate_2 !== 0) {
          timestampIndex[serie.timestamp].stbInFlow = timestampIndex[serie.timestamp].STARBOARD_IN.additional.AVG_dlfmVolFlowrate_2;
        } else {
          timestampIndex[serie.timestamp].stbInFlow = timestampIndex[serie.timestamp].STARBOARD_IN.volumeFlow;
        }
        timestampIndex[serie.timestamp].stbInDens = timestampIndex[serie.timestamp].STARBOARD_IN.density;
        timestampIndex[serie.timestamp].stbOutDens = 0;
        timestampIndex[serie.timestamp].stbOutFlow = 0;
      }
      if (timestampIndex[serie.timestamp].STARBOARD_IN !== undefined && !firstFlowMeterData.STARBOARD_IN) {
        firstFlowMeterData.STARBOARD_IN = timestampIndex[serie.timestamp].STARBOARD_IN;
      }
      if (timestampIndex[serie.timestamp].STARBOARD_OUT !== undefined && !firstFlowMeterData.STARBOARD_OUT) {
        firstFlowMeterData.STARBOARD_OUT = timestampIndex[serie.timestamp].STARBOARD_OUT;
      }
      if (timestampIndex[serie.timestamp].STARBOARD_IN !== undefined) {
        lastFlowMeterData.STARBOARD_IN = timestampIndex[serie.timestamp].STARBOARD_IN;
      }
      if (timestampIndex[serie.timestamp].STARBOARD_OUT !== undefined) {
        lastFlowMeterData.STARBOARD_OUT = timestampIndex[serie.timestamp].STARBOARD_OUT;
      }
      // AE
      if (timestampIndex[serie.timestamp].AE_IN && timestampIndex[serie.timestamp].AE_OUT) {
        if (timestampIndex[serie.timestamp].AE_IN.volumeFlow !== undefined && timestampIndex[serie.timestamp].AE_OUT.volumeFlow !== undefined) {
          timestampIndex[serie.timestamp].aeFuelCons = timestampIndex[serie.timestamp].AE_IN.volumeFlow - timestampIndex[serie.timestamp].AE_OUT.volumeFlow;
        }
        if (timestampIndex[serie.timestamp].AE_IN.volumeFlowrate !== 0) {
          timestampIndex[serie.timestamp].aeInFlow = timestampIndex[serie.timestamp].AE_IN.volumeFlowrate;
        } else {
          timestampIndex[serie.timestamp].aeInFlow = timestampIndex[serie.timestamp].AE_IN.volumeFlow;
        }
        timestampIndex[serie.timestamp].aeInDens = timestampIndex[serie.timestamp].AE_IN.density;
        if (timestampIndex[serie.timestamp].AE_OUT.volumeFlowrate !== 0) {
          timestampIndex[serie.timestamp].aeOutFlow = timestampIndex[serie.timestamp].AE_OUT.volumeFlowrate;
        } else {
          timestampIndex[serie.timestamp].aeOutFlow = timestampIndex[serie.timestamp].AE_OUT.volumeFlow;
        }
        timestampIndex[serie.timestamp].aeOutDens = timestampIndex[serie.timestamp].AE_OUT.density;
        timestampIndex[serie.timestamp].aeFlow = timestampIndex[serie.timestamp].aeInFlow - timestampIndex[serie.timestamp].aeOutFlow;
      }
      if (timestampIndex[serie.timestamp].AE_IN !== undefined && !firstFlowMeterData.AE_IN) {
        firstFlowMeterData.AE_IN = timestampIndex[serie.timestamp].AE_IN;
      }
      if (timestampIndex[serie.timestamp].AE_OUT !== undefined && !firstFlowMeterData.AE_OUT) {
        firstFlowMeterData.AE_OUT = timestampIndex[serie.timestamp].AE_OUT;
      }
      if (timestampIndex[serie.timestamp].AE_IN !== undefined) {
        lastFlowMeterData.AE_IN = timestampIndex[serie.timestamp].AE_IN;
      }
      if (timestampIndex[serie.timestamp].AE_OUT !== undefined) {
        lastFlowMeterData.AE_OUT = timestampIndex[serie.timestamp].AE_OUT;
      }
      if (timestampIndex[serie.timestamp].portFuelCons && timestampIndex[serie.timestamp].stbFuelCons) {
        timestampIndex[serie.timestamp].meFuelCons = timestampIndex[serie.timestamp].portFuelCons + timestampIndex[serie.timestamp].stbFuelCons;
      } else {
        timestampIndex[serie.timestamp].meFuelCons = 0;
      }
      if (timestampIndex[serie.timestamp].portFuelCons < 0) timestampIndex[serie.timestamp].portFuelCons = 0;
      if (timestampIndex[serie.timestamp].stbFuelCons < 0) timestampIndex[serie.timestamp].stbFuelCons = 0;
      if (timestampIndex[serie.timestamp].aeFuelCons < 0) timestampIndex[serie.timestamp].aeFuelCons = 0;
    }
  });
  // https://dev.local.ramus.id/report/15/table?mode=period&start=1619830500000&end=1619870100000&value=today&aggregatedUnit=MINUTE
  // const timeArray = Object.keys(timestampIndex);
  if (firstFlowMeterData && lastFlowMeterData) {
    if (lastFlowMeterData.PORT_IN && firstFlowMeterData.PORT_IN && lastFlowMeterData.PORT_OUT && firstFlowMeterData.PORT_OUT) {
      const portInVolume = lastFlowMeterData.PORT_IN.additional.MAX_dlfmVolInventory_1 - firstFlowMeterData.PORT_IN.additional.MIN_dlfmVolInventory_0;
      const portOutVolume = lastFlowMeterData.PORT_OUT.additional.MAX_dlfmVolInventory_1 - firstFlowMeterData.PORT_OUT.additional.MIN_dlfmVolInventory_0;
      flowmeterSummary.portTotalAverageFlow = flowmeterSummary.PORT_IN._averageFlowRate - flowmeterSummary.PORT_OUT._averageFlowRate;
      if (flowmeterSummary.portTotalAverageFlow > 0) {
        flowmeterSummary.portAverageFlow = flowmeterSummary.portTotalAverageFlow / flowmeterSummary.PORT_IN._dataFreq;
      } else {
        flowmeterSummary.portAverageFlow = 0;
      }
      // const portInVolume = lastFlowMeterData.PORT_IN.volumeInventory - firstFlowMeterData.PORT_IN.volumeInventory;
      // const portOutVolume = lastFlowMeterData.PORT_OUT.volumeInventory - firstFlowMeterData.PORT_OUT.volumeInventory;
      flowmeterSummary.portEngineCons = portInVolume - portOutVolume;
      // console.log(portInVolume, portOutVolume, flowmeterSummary.portEngineCons);
    } else {
      flowmeterSummary.portEngineCons = totalPortFlowrate;
    }
    if (lastFlowMeterData.STARBOARD_IN && firstFlowMeterData.STARBOARD_IN && lastFlowMeterData.STARBOARD_OUT && firstFlowMeterData.STARBOARD_OUT) {
      const stbInVolume = lastFlowMeterData.STARBOARD_IN.additional.MAX_dlfmVolInventory_1 - firstFlowMeterData.STARBOARD_IN.additional.MIN_dlfmVolInventory_0;
      const stbOutVolume = lastFlowMeterData.STARBOARD_OUT.additional.MAX_dlfmVolInventory_1 - firstFlowMeterData.STARBOARD_OUT.additional.MIN_dlfmVolInventory_0;
      flowmeterSummary.starboardTotalAverageFlow = flowmeterSummary.STARBOARD_IN._averageFlowRate - flowmeterSummary.STARBOARD_OUT._averageFlowRate;
      if (flowmeterSummary.starboardTotalAverageFlow > 0) {
        flowmeterSummary.starboardAverageFlow = flowmeterSummary.starboardTotalAverageFlow / flowmeterSummary.STARBOARD_IN._dataFreq;
      } else {
        flowmeterSummary.starboardAverageFlow = 0;
      }
      // console.log('STiN : ', stbInVolume, stbOutVolume);
      // const stbInVolume = lastFlowMeterData.STARBOARD_IN.volumeInventory - firstFlowMeterData.STARBOARD_IN.volumeInventory;
      // const stbOutVolume = lastFlowMeterData.STARBOARD_OUT.volumeInventory - firstFlowMeterData.STARBOARD_OUT.volumeInventory;
      flowmeterSummary.stbEngineCons = stbInVolume - stbOutVolume;
    } else {
      flowmeterSummary.stbEngineCons = totalStbFlowrate;
    }
    // adjust ae in ae out
    if (lastFlowMeterData.AE_IN && firstFlowMeterData.AE_IN && lastFlowMeterData.AE_OUT && firstFlowMeterData.AE_OUT) {
      const aeInVolume = lastFlowMeterData.AE_IN.additional.MAX_dlfmVolInventory_1 - firstFlowMeterData.AE_IN.additional.MIN_dlfmVolInventory_0;
      const aeOutVolume = lastFlowMeterData.AE_OUT.additional.MAX_dlfmVolInventory_1 - firstFlowMeterData.AE_OUT.additional.MIN_dlfmVolInventory_0;
      flowmeterSummary.aeTotalAverageFlow = flowmeterSummary.AE_IN._averageFlowRate - flowmeterSummary.AE_OUT._averageFlowRate;
      if (flowmeterSummary.aeTotalAverageFlow > 0) {
        flowmeterSummary.aeAverageFlow = flowmeterSummary.aeTotalAverageFlow / flowmeterSummary.AE_IN._dataFreq;
      } else {
        flowmeterSummary.aeAverageFlow = 0;
      }
      // console.log('STiN : ', stbInVolume, stbOutVolume);
      // const stbInVolume = lastFlowMeterData.STARBOARD_IN.volumeInventory - firstFlowMeterData.STARBOARD_IN.volumeInventory;
      // const stbOutVolume = lastFlowMeterData.STARBOARD_OUT.volumeInventory - firstFlowMeterData.STARBOARD_OUT.volumeInventory;
      flowmeterSummary.aeEngineCons = aeInVolume - aeOutVolume;
    }
    // console.log('AAA : ', firstFlowMeterData, lastFlowMeterData);
  }
  // console.log('FM Data : ', timestampIndex);
  return {
    data: timestampIndex,
    summary: flowmeterSummary,
  };
}

/*
  Data to check https://dev.local.ramus.id/report/30/gps?mode=period&start=1665633600000&end=1665644400135&value=today&aggregatedUnit=MINUTE
  should be ~4.24 knot
*/
function constructGPSData(dataSeries) {
  // console.log('Construct GPS data series... ', dataSeries);
  const timestampIndex = {};
  const gpsSummary = {
    interval: dataSeries.interval,
    start: dataSeries.from,
    end: dataSeries.to,
    totalPolyLength: 0,
    totalDistance: 0,
    averageSpeed: 0,
    totalDuration: 0,
    movingTime: 0,
    calculatedSpeed: 0,
    calculatedSpeedKMh: 0,
  };
  let totalSpeed = 0;
  if (dataSeries.series && dataSeries.series.length > 0) {
    dataSeries.series.forEach((serie, serieIndex) => {
      // console.log(serie);
      if (serieIndex > 0) {
        gpsSummary.totalDuration += serie.timestamp - dataSeries.series[serieIndex - 1].timestamp;
        const meterPerSecond = serie.distance / (serie.timestamp - dataSeries.series[serieIndex - 1].timestamp);
        if (meterPerSecond > 0.05) {
          // console.log('Meter per second : ', meterPerSecond);
          gpsSummary.movingTime += serie.timestamp - dataSeries.series[serieIndex - 1].timestamp;
        } else {
          console.log('Not moving');
        }
      }
      // do timing correction
      const timeToMin = round(serie.timestamp, dataSeries.interval);
      if ((serie.timestamp - timeToMin) !== 0 && (serie.timestamp - timeToMin) < dataSeries.interval) {
        serie.timestamp -= serie.timestamp - timeToMin;
      }
    });
    dataSeries.series.forEach((serie, index) => {
      if (index !== 0) {
        serie.polyLength = new LatLon(
          dataSeries.series[index - 1].latitude,
          dataSeries.series[index - 1].longitude,
        ).distanceTo(new LatLon(
          serie.latitude,
          serie.longitude,
        ));
      } else {
        serie.polyLength = 0;
      }
      gpsSummary.totalPolyLength += serie.polyLength;
      // console.log('D : ', serie.additional.SUM_dlgpDistance_3);
      gpsSummary.totalDistance += serie.additional.SUM_dlgpDistance_3;
      totalSpeed += serie.additional.AVG_dlgpSpeed_0;
      if (!timestampIndex[serie.timestamp]) timestampIndex[serie.timestamp] = serie;
    });
    gpsSummary.distance = new LatLon(
      dataSeries.series[dataSeries.series.length - 1].additional.LAST_dlgpLatitude_4,
      dataSeries.series[dataSeries.series.length - 1].additional.LAST_dlgpLongitude_5,
    ).distanceTo(new LatLon(
      dataSeries.series[0].latitude,
      dataSeries.series[0].longitude,
    ));
    console.log('first lat', dataSeries.series[0].latitude);
    console.log('first long', dataSeries.series[0].longitude);
    console.log('last lat: ', dataSeries.series[dataSeries.series.length - 1].latitude);
    console.log('last long: ', dataSeries.series[dataSeries.series.length - 1].longitude);
    gpsSummary.averageSpeed = totalSpeed / dataSeries.series.length;
    if (gpsSummary.totalPolyLength > 0 && gpsSummary.movingTime > 0) {
      gpsSummary.calculatedSpeed = (gpsSummary.totalPolyLength / gpsSummary.movingTime) * 1.943844;
      gpsSummary.calculatedSpeedKMh = (gpsSummary.totalPolyLength / gpsSummary.movingTime) * 3.6;
    }
    console.log('Knot : ', gpsSummary.calculatedSpeed);
  }
  return {
    data: timestampIndex,
    summary: gpsSummary,
  };
}

function constructRPMData(dataSeries) {
  let rpmstart = 400;
  if (dataSeries && dataSeries.devices && dataSeries.devices[0]) {
    switch (dataSeries.devices[0]) {
      case 'rpm-mdm_medan1':
        rpmstart = 30;
        break;
      case 'rpm-mdm_bacin':
        rpmstart = 300;
        break;
    }
  }
  const countedRPM = {};
  const timestampIndex = {};
  const rpmSummary = {
    singleEngine: isSingleEngine,
    interval: dataSeries.interval,
    start: dataSeries.from,
    end: dataSeries.to,
    runningTime: {},
    dataCount: {},
    average: {
      PORT: 0,
      STARBOARD: 0,
      MAINENGINE: 0,
    },
    totalRPM: {},
  };
  // console.log('DSSSSS : ', dataSeries);
  // const testSum = {
  //   PORT: 0,
  //   STARBOARD: 0,
  // };
  dataSeries.series.forEach((serie) => {
    // console.log('Construct RPM data series... ', assetConfig[serie.deviceId]);
    // do timing correction
    // if (doTimingCorrection) { // HS
    const timeToMin = round(serie.timestamp, dataSeries.interval);
    if ((serie.timestamp - timeToMin) !== 0 && (serie.timestamp - timeToMin) < dataSeries.interval) {
      serie.timestamp -= serie.timestamp - timeToMin;
    } // HS
    // }
    console.log('rpm-dedevices', (assetConfig[serie.deviceId] && assetConfig[serie.deviceId].isSingleEngine), singleEngineIds.includes(serie.deviceId));
    if ((assetConfig[serie.deviceId] && assetConfig[serie.deviceId].isSingleEngine) && singleEngineIds.includes(serie.deviceId)) {
      rpmSummary.singleEngine = assetConfig[serie.deviceId].isSingleEngine;
    }
    if (rpmSummary.singleEngine === true) {
      serie.type = 'MAINENGINE';
    }
    // console.log(serie);
  });
  const lastTimestamp = {};
  dataSeries.series.forEach((serie) => {
    // console.log('SERIE : ', serie, dataSeries.interval);
    if (!lastTimestamp[serie.type]) lastTimestamp[serie.type] = 0;
    // console.log(assetConfig[serie.deviceId]);
    if (!timestampIndex[serie.timestamp]) timestampIndex[serie.timestamp] = {};
    if (serie.additional.AVG_dlrpRpm_0) {
      serie.rpm = serie.additional.AVG_dlrpRpm_0;
    }
    if (!timestampIndex[serie.timestamp][serie.type]) timestampIndex[serie.timestamp][serie.type] = serie;
    if (!rpmSummary.runningTime[serie.type]) rpmSummary.runningTime[serie.type] = 0;
    if (!rpmSummary.totalRPM[serie.type]) rpmSummary.totalRPM[serie.type] = 0;
    if (!countedRPM[serie.type]) countedRPM[serie.type] = 0;
    if (!rpmSummary.dataCount[serie.type]) rpmSummary.dataCount[serie.type] = 0;
    // rpmSummary.dataCount[serie.type] += serie.additional.COUNT_dlrpRpm_1 * 60;
    // console.log('Test Sum : ', rpmSummary);
    if (timestampIndex[serie.timestamp][serie.type].rpm > rpmstart) {
      countedRPM[serie.type] += serie.additional.COUNT_dlrpRpm_1;
      // if (serie.rpm > rpmstart && serie.timestamp - lastTimestamp[serie.type] <= dataSeries.interval) {
      if (serie.rpm > rpmstart) {
        // console.log(serie.timestamp - lastTimestamp[serie.type], dataSeries.interval);
        timestampIndex[serie.timestamp][serie.type].runningTime = timestampIndex[serie.timestamp][serie.type].additional.COUNT_dlrpRpm_1 * 60;
      }
      if (lastTimestamp[serie.type] !== serie.timestamp) {
        // HS
        // if (serie.timestamp - lastTimestamp[serie.type] !== 60 && lastTimestamp[serie.type] !== 0) {
        //   rpmSummary.runningTime[serie.type] += (serie.timestamp - lastTimestamp[serie.type]);
        //   lastTimestamp[serie.type] = serie.timestamp;
        // } else {
        //   lastTimestamp[serie.type] = serie.timestamp;
        //   rpmSummary.runningTime[serie.type] += timestampIndex[serie.timestamp][serie.type].runningTime;
        // }
        lastTimestamp[serie.type] = serie.timestamp;
        rpmSummary.runningTime[serie.type] += timestampIndex[serie.timestamp][serie.type].runningTime;
      }
      // console.log('RT : ', timestampIndex[serie.timestamp][serie.type].runningTime);
      rpmSummary.totalRPM[serie.type] += timestampIndex[serie.timestamp][serie.type].rpm * serie.additional.COUNT_dlrpRpm_1;
    }
    // if (!timestampIndex[serie.timestamp].MAINENGINE) {
    //   timestampIndex[serie.timestamp].MAINENGINE = JSON.parse(JSON.stringify(serie));
    //   timestampIndex[serie.timestamp].MAINENGINE.type = 'MAINENGINE';
    // }
    if (countedRPM[serie.type] === 0) {
      rpmSummary.average[serie.type] = 0;
    } else {
      rpmSummary.average[serie.type] = rpmSummary.totalRPM[serie.type] / countedRPM[serie.type];
      // rpmSummary.average.MAINENGINE = rpmSummary.average[serie.type];
    }
    // console.log('TS serie', timestampIndex);
  });
  return {
    data: timestampIndex,
    summary: rpmSummary,
  };
}

function constructAEData(dataSeries) {
  console.log('dataSeries', dataSeries);
  // console.log('Construct AE data series... ', dataSeries.devices[0]);
  let fuelConsumptionPerHour = 5;
  if (dataSeries && dataSeries.devices && dataSeries.devices[0]) {
    switch (dataSeries.devices[0]) {
      case 'ae-1':
        fuelConsumptionPerHour = 3.6;
        break;
      case 'ae-m21':
        fuelConsumptionPerHour = 6;
        break;
      case 'ae-m25':
        fuelConsumptionPerHour = 6;
        break;
    }
  }
  const timestampIndex = {};
  const aeSummary = {
    interval: dataSeries.interval,
    start: dataSeries.from,
    end: dataSeries.to,
    runningTime: {},
    runningSeconds: {},
    fuelConsumption: {},
  };
  dataSeries.series.forEach((serie) => {
    // do timing correction
    // if (doTimingCorrection) { // HS
    const timeToMin = round(serie.timestamp, dataSeries.interval);
    if ((serie.timestamp - timeToMin) !== 0 && (serie.timestamp - timeToMin) < dataSeries.interval) {
      serie.timestamp -= serie.timestamp - timeToMin;
    }
    // } // HS
  });
  dataSeries.series.forEach((serie) => {
    if (!timestampIndex[serie.timestamp]) timestampIndex[serie.timestamp] = {};
    if (serie.additional.AVG_dlaeRpm_0 !== 0) {
      serie.runningTime = (serie.additional.AVG_dlaeRpm_0 / 60) * dataSeries.interval;
      serie.runningSeconds = serie.additional.SUM_dlaeRpm_1;
      // console.log('Timestamp : ', serie.timestamp, 'No : ', serie.no, 'Sum : ', serie.runningSeconds);
      serie.fuelConsumption = fuelConsumptionPerHour * (serie.runningSeconds / 3600);
    } else {
      serie.runningTime = 0;
      serie.runningSeconds = 0;
      serie.fuelConsumption = 0;
    }
    if (!timestampIndex[serie.timestamp][`AE${serie.no + 1}`]) timestampIndex[serie.timestamp][`AE${serie.no + 1}`] = serie;
    if (!aeSummary.runningTime[`AE${serie.no + 1}`]) aeSummary.runningTime[`AE${serie.no + 1}`] = 0;
    if (!aeSummary.runningSeconds[`AE${serie.no + 1}`]) aeSummary.runningSeconds[`AE${serie.no + 1}`] = 0;
    if (!aeSummary.fuelConsumption[`AE${serie.no + 1}`]) aeSummary.fuelConsumption[`AE${serie.no + 1}`] = 0;
    aeSummary.runningTime[`AE${serie.no + 1}`] += serie.runningTime;
    aeSummary.runningSeconds[`AE${serie.no + 1}`] += serie.runningSeconds;
    aeSummary.fuelConsumption[`AE${serie.no + 1}`] += serie.fuelConsumption;
  });
  return {
    data: timestampIndex,
    summary: aeSummary,
  };
}

// console.log('TEST : ', round(36, 6));

export default {
  getDevicesData(devices, params) {
    console.log('Get devices data using param : ', devices);
    const promises = [];
    const constructedParams = {};
    let filterrpm = ['@Rpm|400|gte'];
    for (let i = 0; i < devices.length; i++) {
      const device = devices[i];
      if (params.typesToGet && params.typesToGet.indexOf(device.devcType) !== -1) {
        if (!constructedParams[device.devcType]) constructedParams[device.devcType] = JSON.parse(JSON.stringify(params));
        if (!constructedParams[device.devcType].devices) constructedParams[device.devcType].devices = [];
        constructedParams[device.devcType].devices.push(device.devcUniqueId);
        switch (device.devcType) {
          case 'gps':
            // constructedParams[device.devcType].primaryFunctionName = 'LAST';
            // constructedParams[device.devcType].aggregatedLength = 2;
            constructedParams[device.devcType].functionNames = ['AVG', 'AVG', 'AVG', 'SUM', 'LAST', 'LAST'];
            constructedParams[device.devcType].functionParams = ['@Speed', '@Track', '@Altitude', '@Distance', '@Latitude', '@Longitude'];
            // constructedParams[device.devcType].additionalFilter = ['@Speed|0|gt'];
            console.log('getDevicesData gps>>', constructedParams[device.devcType]);
            break;
          case 'rpm':
            // console.log('Asset id : ', assetConfig[device.devcMassId]);
            switch (device.devcMassId) {
              case '42':
                filterrpm = ['@Rpm|30|gte'];
                break;
              case '30':
                filterrpm = ['@Rpm|300|gte'];
                break;
            }
            console.log('Filter RPM : ', filterrpm);
            // constructedParams[device.devcType].primaryFunctionName = 'LAST';
            // constructedParams[device.devcType].aggregatedLength = 2;
            constructedParams[device.devcType].type = ['PORT', 'STARBOARD'];
            constructedParams[device.devcType].functionNames = ['AVG', 'COUNT', 'MIN', 'MAX'];
            constructedParams[device.devcType].functionParams = ['@Rpm', '@Rpm', '@Rpm', '@Rpm'];
            constructedParams[device.devcType].additionalFilter = filterrpm;
            break;
          case 'ae':
            // constructedParams[device.devcType].primaryFunctionName = 'LAST';
            // constructedParams[device.devcType].aggregatedLength = 2;
            constructedParams[device.devcType].functionNames = ['AVG', 'SUM'];
            constructedParams[device.devcType].functionParams = ['@Rpm', '@Rpm'];
            break;
          case 'flowmeter':
            // constructedParams[device.devcType].primaryFunctionName = 'LAST';
            // constructedParams[device.devcType].aggregatedLength = 2;
            constructedParams[device.devcType].functionNames = ['MIN', 'MAX', 'AVG', 'AVG', 'AVG', 'SUM'];
            constructedParams[device.devcType].functionParams = ['@VolInventory', '@VolInventory', '@VolFlowrate', '@Density', '@Temp', '@VolFlowrate'];
            // constructedParams[device.devcType].additionalFilter = ['@MassInventory|0|gt'];
            break;
        }
      }
    }
    console.log('Constructed params for each keys : ', constructedParams);
    Object.keys(constructedParams).forEach((typeToGet) => {
      promises.push(this.getAggregatedDatas(constructedParams, typeToGet));
    });
    return Promise.all(promises);
  },
  async getCombinedData(devices, params) {
    console.log('Get combined data of these devices... ', devices, params);
    const devicesData = await this.getDevicesData(devices, params);
    console.log('Raw devices data :', devicesData);
    let timeIndex;
    const summaries = {};
    const buffer = {};
    let earliestData;
    let latestData;
    let lastDataTime = 0;
    let isFlowmeterExist = false;
    let isAeExist = false;
    let isGpsExist = false;
    let isRpmExist = false;
    let isFlowmeterAeExist = false;
    let flowmeterChecking = {};
    const lastData = {};
    // let firstDataTime;
    let lastGPSData;

    // Check data
    devicesData.forEach((deviceData) => {
      if (deviceData.status === 200 && deviceData.data.status.error === 0) {
        const deviceType = deviceData.config.params.deviceType;
        if (deviceType === 'flowmeter' && isFlowmeterExist === false) {
          isFlowmeterExist = true;
        } else if (deviceType === 'ae' && isAeExist === false) {
          isAeExist = true;
        } else if (deviceType === 'gps' && isGpsExist === false) {
          isGpsExist = true;
        } else if (deviceType === 'rpm' && isRpmExist === false) {
          isRpmExist = true;
        }
      }
    });

    devicesData.forEach((deviceData) => {
      const deviceType = deviceData.config.params.deviceType;
      switch (deviceType) {
        case 'flowmeter':
          flowmeterChecking = constructFlowMeterData(
            deviceData.data.data,
            devices,
          );
      }
    });
    if (flowmeterChecking && flowmeterChecking.summary && (flowmeterChecking.summary.AE_IN || flowmeterChecking.summary.AE_OUT)) {
      isFlowmeterAeExist = true;
    }

    devicesData.forEach((deviceData) => {
      const devicesObject = {};
      devices.forEach((device) => {
        devicesObject[device.devcUniqueId] = device;
      });
      if (deviceData.status === 200 && deviceData.data.status.error === 0) {
        // console.log('AAA : ', buffer);
        // if (deviceData.data.data.devices.length > 0) {
        const deviceType = deviceData.config.params.deviceType;
        let last = 0;
        switch (deviceType) {
          case 'gps':
            buffer[deviceType] = constructGPSData(deviceData.data.data);
            Object.keys(buffer[deviceType].data).forEach((t) => {
              if (parseInt(t) > last) {
                last = parseInt(t);
                if (buffer[deviceType].data[t].satellites) {
                  lastData[deviceType] = buffer[deviceType].data[t];
                }
                // console.log('GPS Data : ', buffer[deviceType].data[t].satellites);
              }
            });
            break;
          case 'rpm':
            buffer[deviceType] = constructRPMData(deviceData.data.data);
            // console.log('DD : ', deviceData.data.data, constructRPMData(deviceData.data.data));
            Object.keys(buffer[deviceType].data).forEach((t) => {
              // console.log('T: ', new Date(parseInt(t) * 1000));
              if (parseInt(t) > last) {
                last = parseInt(t);
                if (buffer[deviceType].data[t]) {
                  lastData[deviceType] = buffer[deviceType].data[t];
                } else {
                  console.log('----------- >>>>>', buffer[deviceType]);
                }
                // console.log('GPS Data : ', buffer[deviceType].data[t]);
              }
            });
            // console.log('RPM Data : ', Object.keys(constructRPMData(deviceData.data.data).data));
            // console.log('RPM : ', JSON.stringify(buffer[deviceType]));
            break;
          case 'ae':
            buffer[deviceType] = constructAEData(deviceData.data.data);
            // console.log('AE Data : ', Object.keys(constructAEData(deviceData.data.data).data));
            break;
          case 'flowmeter':
            console.log('Flowmeter Data : ', Object.keys(constructFlowMeterData(deviceData.data.data, devices).data));
            buffer[deviceType] = constructFlowMeterData(deviceData.data.data, devices);
            Object.keys(buffer[deviceType].data).forEach((t) => {
              if (parseInt(t) > last) {
                last = parseInt(t);
                if (buffer[deviceType].data[t].meFuelCons !== undefined) {
                  lastData[deviceType] = buffer[deviceType].data[t];
                }
                // console.log('FM Data : ', buffer[deviceType].data[t]);
              }
            });
            break;
        }
        // console.log('Last Data : ', lastData);
        if (!summaries[deviceType]) summaries[deviceType] = buffer[deviceType].summary;
        if (!earliestData) earliestData = buffer[deviceType].summary.start;
        if (!latestData) latestData = buffer[deviceType].summary.end;
        if (buffer[deviceType].summary.start < earliestData) earliestData = buffer[deviceType].summary.start;
        if (buffer[deviceType].summary.end > latestData) latestData = buffer[deviceType].summary.end;
        const dataArray = Object.keys(buffer[deviceType].data);
        if (!timeIndex) {
          // console.log(dataArray[0], dataArray[dataArray.length - 1]);
          // console.log(buffer[deviceType]);
          timeIndex = {};
          // const repeat = (parseInt(dataArray[dataArray.length - 1]) - parseInt(dataArray[0])) / buffer[deviceType].summary.interval;
          // console.log('Create time index based on interval... ', buffer[deviceType].summary.start, buffer[deviceType].summary.end, buffer[deviceType].summary.interval);
          // for (let i = 1; i < repeat + 1; i++) {
          //   // timeIndex[buffer[deviceType].summary.start + (buffer[deviceType].summary.interval * i)] = {};
          //   // timeIndex[parseInt(dataArray[0]) + (buffer[deviceType].summary.interval * i)] = {};
          // }
        }
        // console.log('Data Array : ', deviceType, dataArray);
        // https://dev.local.ramus.id/report/15/summary?mode=period&start=1619456400000&end=1619467800057&value=today&aggregatedUnit=MINUTEx
        dataArray.forEach((time, index) => {
          // console.log('dataarray index', time, index);
          const timeInt = parseInt(time);
          const timeToMin = round(timeInt, buffer[deviceType].summary.interval);
          // if (firstDataTime === undefined) firstDataTime = timeToMin;
          // console.log('AAA : ', index, timeToMin);
          if (parseInt(timeToMin) > parseInt(lastDataTime)) { // HS
          // if (timeInt > parseInt(lastDataTime)) { // HS
            lastDataTime = timeInt;
          }
          // if (parseInt(timeToMin) < parseInt(firstDataTime)) {
          //   firstDataTime = timeToMin;
          // }
          if (index >= 0) {
            // if ((timeInt - timeToMin) !== 0 && (timeInt - timeToMin) < buffer[deviceType].summary.interval) {
            //   console.log('Do timing correction : ', timeInt - timeToMin);
            //   timeInt -= buffer[deviceType].summary.interval +timeInt - timeToMin;
            // }
            if ((timeInt === timeToMin)) {
              // const timeToMin = new Date(new Date(timeInt * 1000).setSeconds(0)).getTime() / 1000;
              if (!timeIndex[timeToMin]) timeIndex[timeToMin] = {};
              timeIndex[timeToMin][deviceType] = buffer[deviceType].data[timeInt.toString()];
              // console.log('Time : ', time, timeToMin, timeIndex[timeToMin]);
              if (deviceType === 'gps') {
                lastGPSData = buffer[deviceType].data[time];
                // console.log(lastGPSData);
              }

              // adjust gps default data
              if (!timeIndex[timeToMin].gps) {
                timeIndex[timeToMin].gps = lastGPSData;
              }
              // adjust ae default data
              if (isAeExist && !timeIndex[timeToMin].ae) {
                timeIndex[timeToMin].ae = {
                  AE1: {
                    no: 0,
                    rpm: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      AVG_dlaeRpm_0: 0,
                      SUM_dlaeRpm_1: 0,
                    },
                    runningTime: 0,
                    runningSeconds: 0,
                    fuelConsumption: 0,
                  },
                  AE2: {
                    no: 0,
                    rpm: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      AVG_dlaeRpm_0: 0,
                      SUM_dlaeRpm_1: 0,
                    },
                    runningTime: 0,
                    runningSeconds: 0,
                    fuelConsumption: 0,
                  },
                  AE3: {
                    no: 0,
                    rpm: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      AVG_dlaeRpm_0: 0,
                      SUM_dlaeRpm_1: 0,
                    },
                    runningTime: 0,
                    runningSeconds: 0,
                    fuelConsumption: 0,
                  },
                };
              }
              console.log('isFlowmeterAeExist', isFlowmeterAeExist);

              // adjust flowmeter default data
              if (isFlowmeterExist && !timeIndex[timeToMin].flowmeter && isFlowmeterAeExist === false) {
                timeIndex[timeToMin].flowmeter = {
                  PORT_IN: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                      SUM_dlfmVolFlowrate_5: 0,
                    },
                    volumeFlow: 0,
                  },
                  PORT_OUT: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                      SUM_dlfmVolFlowrate_5: 0,
                    },
                    volumeFlow: 0,
                  },
                  STARBOARD_IN: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                      SUM_dlfmVolFlowrate_5: 0,
                    },
                    volumeFlow: 0,
                  },
                  STARBOARD_OUT: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                      SUM_dlfmVolFlowrate_5: 0,
                    },
                    volumeFlow: 0,
                  },
                  meFuelCons: 0,
                  portFlow: 0,
                  portFuelCons: 0,
                  portInDens: 0,
                  portInFlow: 0,
                  portOutDens: 0,
                  portOutFlow: 0,
                  stbFlow: 0,
                  stbFuelCons: 0,
                  stbInDens: 0,
                  stbInFlow: 0,
                  stbOutDens: 0,
                  stbOutFlow: 0,
                };
              }
              // adjust flowmeter default data with ae
              if (isFlowmeterExist && !timeIndex[timeToMin].flowmeter && isFlowmeterAeExist === true) {
                timeIndex[timeToMin].flowmeter = {
                  PORT_IN: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                    },
                    volumeFlow: 0,
                  },
                  PORT_OUT: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                    },
                    volumeFlow: 0,
                  },
                  STARBOARD_IN: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                    },
                    volumeFlow: 0,
                  },
                  STARBOARD_OUT: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                    },
                    volumeFlow: 0,
                  },
                  AE_IN: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                    },
                    volumeFlow: 0,
                  },
                  AE_OUT: {
                    massFlowrate: 0,
                    density: 0,
                    temperature: 0,
                    volumeFlowrate: 0,
                    massTotal: 0,
                    volumeTotal: 0,
                    massInventory: 0,
                    volumeInventory: 0,
                    id: 0,
                    deviceId: '',
                    timestamp: time,
                    datetime: '',
                    additional: {
                      MIN_dlfmVolInventory_0: 0,
                      MAX_dlfmVolInventory_1: 0,
                      AVG_dlfmVolFlowrate_2: 0,
                      AVG_dlfmDensity_3: 0,
                      AVG_dlfmTemp_4: 0,
                    },
                    volumeFlow: 0,
                  },
                  meFuelCons: 0,
                  portFlow: 0,
                  portFuelCons: 0,
                  portInDens: 0,
                  portInFlow: 0,
                  portOutDens: 0,
                  portOutFlow: 0,
                  stbFlow: 0,
                  stbFuelCons: 0,
                  stbInDens: 0,
                  stbInFlow: 0,
                  stbOutDens: 0,
                  stbOutFlow: 0,
                  aeFlow: 0,
                  aeFuelCons: 0,
                  aeInDens: 0,
                  aeInFlow: 0,
                  aeOutDens: 0,
                  aeOutFlow: 0,
                };
              }

              // adjust rpm default data
              if (!timeIndex[timeToMin].rpm) {
                timeIndex[timeToMin].rpm = {
                  STARBOARD: {
                    type: 'STARBOARD',
                    no: 0,
                    rpm: 0,
                    id: 0,
                    // deviceId: "rpm-k09",
                    timestamp: time,
                    // datetime": "2021-04-24 01:53:00",
                    additional: {
                      AVG_dlrpRpm_0: 0,
                      COUNT_dlrpRpm_1: 0,
                      MIN_dlrpRpm_2: 0,
                      MAX_dlrpRpm_3: 0,
                    },
                    runningTime: 0,
                  },
                  MAINENGINE: {
                    type: 'MAINENGINE',
                    no: 0,
                    rpm: 0,
                    id: 0,
                    // deviceId: "rpm-k09",
                    timestamp: time,
                    // datetime": "2021-04-24 01:53:00",
                    additional: {
                      AVG_dlrpRpm_0: 0,
                      COUNT_dlrpRpm_1: 0,
                      MIN_dlrpRpm_2: 0,
                      MAX_dlrpRpm_3: 0,
                    },
                    runningTime: 0,
                  },
                  PORT: {
                    type: 'PORT',
                    no: 0,
                    rpm: 0,
                    id: 0,
                    // deviceId": "rpm-k09",
                    timestamp: time,
                    // timestamp": 1619229180,
                    // datetime": "2021-04-24 01:53:00",
                    additional: {
                      AVG_dlrpRpm_0: 0,
                      COUNT_dlrpRpm_1: 0,
                      MIN_dlrpRpm_2: 0,
                      MAX_dlrpRpm_3: 0,
                    },
                    runningTime: 0,
                  },
                };
                if (!lastData.rpm) {
                  lastData.rpm = timeIndex[timeToMin].rpm;
                }
              }
              if (!timeIndex[timeToMin].rpm.STARBOARD) {
                timeIndex[timeToMin].rpm.STARBOARD = {
                  type: 'STARBOARD',
                  no: 0,
                  rpm: 0,
                  id: 0,
                  // deviceId: "rpm-k09",
                  timestamp: time,
                  // datetime": "2021-04-24 01:53:00",
                  additional: {
                    AVG_dlrpRpm_0: 0,
                    COUNT_dlrpRpm_1: 0,
                    MIN_dlrpRpm_2: 0,
                    MAX_dlrpRpm_3: 0,
                  },
                  runningTime: 0,
                };
              }
              if (!timeIndex[timeToMin].rpm.MAINENGINE) {
                timeIndex[timeToMin].rpm.MAINENGINE = {
                  type: 'MAINENGINE',
                  no: 0,
                  rpm: 0,
                  id: 0,
                  // deviceId: "rpm-k09",
                  timestamp: time,
                  // datetime": "2021-04-24 01:53:00",
                  additional: {
                    AVG_dlrpRpm_0: 0,
                    COUNT_dlrpRpm_1: 0,
                    MIN_dlrpRpm_2: 0,
                    MAX_dlrpRpm_3: 0,
                  },
                  // "COUNT_dlrpRpm_1": 7
                  runningTime: 0,
                };
              }
              if (!timeIndex[timeToMin].rpm.PORT) {
                timeIndex[timeToMin].rpm.PORT = {
                  type: 'PORT',
                  no: 0,
                  rpm: 0,
                  id: 0,
                  // deviceId: "rpm-k09",
                  timestamp: time,
                  // datetime": "2021-04-24 01:53:00",
                  additional: {
                    AVG_dlrpRpm_0: 0,
                    COUNT_dlrpRpm_1: 0,
                    MIN_dlrpRpm_2: 0,
                    MAX_dlrpRpm_3: 0,
                  },
                  runningTime: 0,
                };
              }
              // if (parseInt(time) - parseInt(dataArray[index - 1]) <= buffer[deviceType].summary.interval) {
              //   timeIndex[time][deviceType] = buffer[deviceType].data[time];
              // } else {
              //   timeIndex[dataArray[index - 1]][deviceType] = buffer[deviceType].data[time];
              //   console.log(dataArray[index - 1]);
              // }
              // console.log('T: ', parseInt(time) - parseInt(dataArray[index - 1]), buffer[deviceType].summary.interval);
            } else if ((timeInt - timeToMin) < buffer[deviceType].summary.interval) {
              // HS console.log(`--- !OUT OF SYNC DATA! ---
              // HS   Data time : ${timeInt}.
              // HS   Binding time : ${timeToMin}.
              // HS   Time gap in seconds : ${timeInt - timeToMin}.
              // HS   Expected data interval : ${buffer[deviceType].summary.interval}.
              // HS   Data to parse : `, buffer[deviceType].data[time]);
              // timeIndex[timeToMin][deviceType] = buffer[deviceType].data[time];
            }
          }
          // else {
          //   if (!timeIndex[time]) timeIndex[time] = {};
          //   timeIndex[time][deviceType] = buffer[deviceType].data[time];
          // }
        });
        // }
      } else {
        console.log('Something Wrong : ', deviceData.data);
      }
    });
    // console.log('Combined Data : ', parseInt(lastDataTime), (new Date().getTime() / 1000));
    // const timeArray = Object.keys(timeIndex);
    // timeArray.forEach((_time, index) => {
    //   const time = parseInt(_time);
    //   console.log('Time Index : ', time, time - parseInt(timeArray[index - 1]));
    // });
    // console.log('Time Index :', Object.keys(timeIndex));
    console.log('summariesez', summaries);
    const _data = {
      datas: timeIndex,
      summaries,
      start: earliestData,
      firstData: timeIndex ? timeIndex[Object.keys(timeIndex)[0]] : undefined,
      end: latestData,
      latestDataTime: lastDataTime,
      fromNow: (new Date().getTime() / 1000) - parseInt(lastDataTime),
      // lastData: timeIndex ? timeIndex[lastDataTime] : undefined,
      lastData,
    };
    if (lastDataTime === 0) {
      _data.fromNow = false;
    }
    console.log('Combined Data : ', _data);
    return _data;
  },
  parseSeries(rawSeries) {
    console.log('Process this : ', rawSeries);
    const series = [];
    rawSeries.series.forEach((serie) => {
      serie.data = JSON.parse(serie.data);
      if (serie.data.readings) {
        if (!serie.deviceData) serie.deviceData = {};
        serie.data.readings.forEach((reading) => {
          if (reading.type === 'flowMeter') {
            if (!serie.deviceData[reading.type]) serie.deviceData[reading.type] = {};
            if (!serie.deviceData[reading.type][reading.deviceId]) serie.deviceData[reading.type][reading.deviceId] = [];
            serie.deviceData[reading.type][reading.deviceId].push(reading);
          } else {
            if (!serie.deviceData[reading.type]) serie.deviceData[reading.type] = [];
            serie.deviceData[reading.type].push(reading);
          }
          // console.log('S: ', reading);
        });
      } else {
        console.log('No readings Object...');
      }
      series.push(serie);
      // console.log(serie);
    });
    return series;
  },
  getLastData(TYPE, device, type) {
    return axios.get(`/series/device/${TYPE}/pulse`, {
      params: {
        device,
        type,
      },
    });
  },
  getAggregatedData(device, params) {
    // console.log('Data to get : ', device, params);
    const _params = {
      device: [device.devcUniqueId],
    };
    if (params.type) _params.type = params.type;
    if (params.aggregatedUnit) _params.aggregatedUnit = params.aggregatedUnit;
    if (params.primaryFunctionName) _params.primaryFunctionName = params.primaryFunctionName;
    if (params.functionNames) _params.functionNames = params.functionNames;
    if (params.functionParams) _params.functionParams = params.functionParams;
    if (params.timezone) _params.timezone = params.timezone;
    if (params.aggregatedLength) _params.aggregatedLength = params.aggregatedLength;
    if (params.mode === 'last') {
      _params.aggregatedLength = 1;
      _params.primaryFunctionName = 'LAST';
      return this.getLastAggregatedData(device.devcType, params.unit, params.duration, {
        params: _params,
      });
    }
    return this.getAggregatedDataByPeriod(device.devcType, params.range.start, params.range.end, {
      params: _params,
    });
    // return axios.get(`/series/aggregated/${deviceType}/last/${duration}/${unit}`, params);
  },
  getAggregatedDatas(allParams, deviceType) {
    // console.log('Data to get : ', allParams, type);
    const params = allParams[deviceType];
    const _params = {
      device: params.devices,
      limit: 10000,
    };
    if (params.type) _params.type = params.type;
    if (deviceType) _params.deviceType = deviceType;
    if (params.aggregatedUnit) _params.aggregatedUnit = params.aggregatedUnit;
    if (params.primaryFunctionName) _params.primaryFunctionName = params.primaryFunctionName;
    if (params.functionNames) _params.functionNames = params.functionNames;
    if (params.functionParams) _params.functionParams = params.functionParams;
    if (params.aggregatedLength) _params.aggregatedLength = params.aggregatedLength;
    if (params.timezone) _params.timezone = params.timezone;
    if (params.additionalFilter) _params.additionalFilter = params.additionalFilter;
    if (params.aggregatedUnit && params.aggregatedUnit === 'HOUR') {
      _params.aggregatedUnit = 'MINUTE';
      _params.aggregatedLength = 60;
    }
    if (params.mode === 'last') {
      if (!_params.aggregatedLength) {
        _params.aggregatedLength = 1;
      }
      _params.primaryFunctionName = 'LAST';
      return this.getLastAggregatedData(deviceType, params.unit, params.duration, {
        params: _params,
      });
    }
    return this.getAggregatedDataByPeriod(deviceType, params.range.start, params.range.end, {
      params: _params,
    });
    // return axios.get(`/series/aggregated/${deviceType}/last/${duration}/${unit}`, params);
  },
  getLastAggregatedData(deviceType, unit, duration, params) {
    return axios.get(`/series/aggregated/${deviceType}/last/${duration}/${unit}`, params);
  },
  getAggregatedDataByPeriod(deviceType, startTimestamp, endTimestamp, params) {
    return axios.get(`/series/aggregated/${deviceType}/period/${startTimestamp}/${endTimestamp}`, params);
  },
  getSeries(start, end, devices, interval) {
    return axios.get(`/series/base/period/${start}/${end}`, {
      params: {
        device: devices,
        interval,
      },
    });
  },
  getWeeklySeries(filter, devices, interval) {
    return this.getSeries(filter.start, filter.end, devices, interval);
  },
  getSeriesByInterval(range, interval, devices, limit) {
    return axios.get(`/series/base/last/${interval}/${range}`, {
      params: {
        device: devices,
        limit,
      },
    });
  },
};
