import { render, staticRenderFns } from "./SummaryView.vue?vue&type=template&id=b1742b90&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform"
import script from "./SummaryView.vue?vue&type=script&lang=js"
export * from "./SummaryView.vue?vue&type=script&lang=js"
import style0 from "./SummaryView.vue?vue&type=style&index=0&id=b1742b90&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1742b90",
  null
  
)

export default component.exports