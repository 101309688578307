<template>
  <div>
    <div role="group" class="form-group">
      <div class="bv-no-focus-ring">
        <b-form-group label="Username" label-for="inputTextEmail">
          <b-form-input id="inputTextEmail" type="text" placeholder="Enter username/email" v-model="form.email"
            :class="errors.email || $v.form.email.$error ? 'is-invalid' : ''">
          </b-form-input>
          <b-form-invalid-feedback v-if="errors.email">
            {{ errors.email }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.email.required">
            Can not be empty
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div role="group" class="form-group">
      <div class="bv-no-focus-ring">
        <b-form-group label="Password" label-for="inputTextPassword">
          <b-form-input id="inputTextPassword" type="password" placeholder="Enter password" v-model="form.password"
            :class="errors.password || $v.form.password.$error ? 'is-invalid' : ''">
          </b-form-input>
          <b-form-invalid-feedback v-if="errors.password">
            {{ errors.password }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.password.required">
            Can not be empty
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div role="group" class="form-group">
      <div class="bv-no-focus-ring">
        <b-form-checkbox id="remember_email" v-model="remember_email" name="remember_email" :value="true"
          :unchecked-value="false">
          Remember my email
        </b-form-checkbox>
      </div>
    </div>
    <div class="form-group">
      <button class="btn w-100 btn-block btn-primary" type="submit" @click="loginUser" :disabled="isSubmit">
        <i v-if="isSubmit" class="ri-loader-line"></i>
        <i v-if="isError" class="ri-alert"></i>
        Sign In
        <i class="ri-arrow-right-s-line"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import axios from 'axios';
// import oktaConfig from '../../config/okta.js';

export default {
  data() {
    return {
      error: null,
      errors: {},
      form: {
        email: undefined,
        password: undefined,
      },
      isSubmit: false,
      isError: true,
      remember_email: true,
    };
  },
  mixins: [validationMixin],
  mounted() {
    const lastEmail = localStorage.getItem('mantraz_login_email');
    if (lastEmail) {
      this.form.email = lastEmail;
      // this.form.email = `adaemail_${lastEmail}`;
    }
  },
  methods: {
    async doneLogin() {
      try {
        // await this.$store.dispatch('auth/signIn', token);
        this.isLoading = false;
        this.$emit('onSuccess');
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.$emit('onFailure', err);
      }
    },
    async loginUser() {
      this.resetError();
      this.isSubmit = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.isSubmit = false;
      }

      const formData = new FormData();
      formData.append('username', this.form.email);
      formData.append('password', this.form.password);
      const res = await axios.post('/security/authentication/login', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        allow_redirects: false,
      });

      if (res.status === 200) {
        const token = res.data.token;

        if (!token) {
          this.isSubmit = false;
          this.errors = {
            email: 'Invalid Username or Password',
            password: 'Invalid Username or Password',
          };
          this.$emit('onFailure', 'failed login');
        }

        localStorage.setItem('mantraz_auth_token', token);
        if (this.remember_email) {
          localStorage.setItem('mantraz_login_email', this.form.email);
        } else {
          localStorage.removeItem('mantraz_login_email');
        }
        this.doneLogin(token);
      } else {
        this.isSubmit = false;
        this.errors = {
          email: 'Invalid Username or Password',
          password: 'Invalid Username or Password',
        };
        this.$emit('onFailure', 'failed login');
      }
    },
    resetError() {
      this.errors = {};
    },
  },
  name: 'LoginForm',
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.email': function () {
      this.$v.form.email.$touch();
    },
    // eslint-disable-next-line func-names
    'form.password': function () {
      this.$v.form.password.$touch();
    },
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #c850af;
  border-color: #c850af;
}

.btn-primary:hover {
  background-color: #b71fa8;
  border-color: #b71fa8;
}
</style>
