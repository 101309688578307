import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import coordinate from './coordinate';
import user from './user';
import breadcrumb from './breadcrumb';
import layout from './layout';
import kapal from './kapal/index';
import asset from './asset';
import filter from './filter';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    filter,
    coordinate,
    user,
    breadcrumb,
    layout,
    kapal,
    asset,
  },
});
