<template>
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">User Login List</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options :access="'master_user_login'" :hideAdd="false" :hideDetail="true" :isRefreshEnabled="true"
                :isRefreshed="isRefreshed" @onAddItemClicked="openAddForm" @onRefreshed="getData('Refreshed')" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col md="12">
            <user-login-list :access="'master_user_login'" :data="data" :loading="isLoading" :pagination="pagination"
              @onActionDelete="openDelete" @onActionEdit="openEdit" @onActionView="openDetail"
              @onPageChanged="onPageChanged" @update="onUpdated" />
          </b-col>
        </b-row>
      </div>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayForm">
        <user-login-form :data="currentUser" :errors="errors" :isSubmit="isSubmit" @onSubmitClicked="onSubmitClicked"
          @onCancelAddClicked="onCancel" />
      </b-modal>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayDeletion">
        <delete-item :isSubmit="isSubmit" @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import DeleteItem from '@/components/directives/delete-item';
import ButtonOptions from '@/components/directives/button-options';
import UserLoginList from '@/components/userrole/user_login/List';
import UserLoginForm from '@/components/userrole/user_login/Form';
import UserSvc from '@/services/UserSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'UserLogin',
  components: {
    DeleteItem, UserLoginForm, UserLoginList, ButtonOptions,
  },
  data() {
    return {
      currentUser: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const resp = await UserSvc.deleteUserLogin(data.usloUserId);
        if (resp.data.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = await false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(resp.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const resp = await UserSvc.getUserLogin({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        this.data = resp.data.data;
        this.pagination.totalSize = resp.data.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.currentUser = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Deleted complete', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    async onSubmitClicked(form) {
      this.errors = {};
      this.isSubmit = true;
      try {
        const resp = await UserSvc.createUserLogin({
          usloUserId: form.usloUserId,
          usloUserName: form.usloUserName,
          usloPassword: form.usloPassword,
        });
        if (resp.data.status.error === 0) {
          await this.getData('Success');
          this.currentUser = {};
          this.displayForm = false;
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
        this.isSubmit = false;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.currentUser = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(data) {
      this.$router.push({ name: 'UserDetail', params: { id: data.userId } });
    },
    openEdit(
      // data
    ) {
      // this.displayForm = true;
      // this.currentUser = { ...this._.find(this.data, (v) => v.userId === data.userId) };
      // this.$router.push({ name: 'UserDetail', params: { id: data.userId } });
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped></style>
