import { icon } from 'leaflet';
import kapalOnline from '@/assets/images/map/online-ship.svg';
import kapalOffline from '@/assets/images/map/offline-ship.svg';

const state = {
  kapals: [
    {
      name: 'Magnolia 65',
      position: [-0.814823, 117.496738],
      coordinate: [
        [-0.814823, 117.496738],
        [-0.8200, 117.4932],
        [-0.8240, 117.4911],
        [-0.8285, 117.4921],
        [-0.8321, 117.4925],
      ],
      trail: false,
      icon: icon({
        iconUrl: kapalOnline,
        iconSize: [35, 61],
        iconAnchor: [12, 41],
        popupAnchor: [0, -41],
        tooltipAnchor: [-50, 30],
        className: 'ship',
      }),
      angle: 20,
      online: true,
      id: 1,
      status: 'online',
      location: 'Pulau Melaka',
      cruise: '10',
      speed: '23.2',
      updated: '28/06/24 17:01',
    },
    {
      name: 'Gardenia 05',
      position: [-0.766473, 117.450746],
      coordinate: [
        [-0.766473, 117.450746],
        [-0.7735, 117.4587],
        [-0.7833, 117.4621],
        [-0.7883, 117.4604],
        [-0.7833, 117.4621],
      ],
      trail: false,
      icon: icon({
        iconUrl: kapalOnline,
        iconSize: [35, 61],
        iconAnchor: [12, 41],
        popupAnchor: [0, -41],
        tooltipAnchor: [-50, 30],
        className: 'ship',
      }),
      angle: 300,
      online: true,
      id: 2,
      status: 'offline',
      location: 'Pulau Melaka',
      cruise: '5',
      speed: '12.2',
      updated: '28/06/24 17:01',
    },
    {
      name: 'Azalea 001',
      position: [-0.837958, 117.423708],
      coordinate: [
        [-0.837958, 117.423708],
        [-0.8327, 117.4390],
        [-0.8343, 117.4457],
        [-0.8408, 117.4457],
        [-0.8484, 117.4513],
        [-0.8537, 117.4566],
      ],
      trail: false,
      icon: icon({
        iconUrl: kapalOffline,
        iconSize: [35, 61],
        iconAnchor: [12, 41],
        popupAnchor: [0, -41],
        tooltipAnchor: [-50, 30],
        className: 'ship',
      }),
      angle: 145,
      online: false,
      id: 3,
      status: 'online',
      location: 'Samarinda',
      cruise: '8',
      speed: '18.2',
      updated: '28/06/24 17:01',
    },
    {
      name: 'Amaryllis 12',
      position: [-0.798092, 117.400335],
      coordinate: [
        [-0.798092, 117.400335],
        [-0.8013, 117.4015],
        [-0.8075, 117.4042],
        [-0.8140, 117.4052],
        [-0.8205, 117.4082],
      ],
      trail: false,
      icon: icon({
        iconUrl: kapalOffline,
        iconSize: [35, 61],
        iconAnchor: [12, 41],
        popupAnchor: [0, -41],
        tooltipAnchor: [-50, 30],
        className: 'ship',
      }),
      angle: 240,
      online: false,
      id: 4,
      status: 'offline',
      location: 'Laut Tengah',
      cruise: '3',
      speed: '10.2',
      updated: '28/06/24 17:01',
    },
  ],
};

const mutations = {
  UPDATE_KAPAL_STATUS(state, { id, status }) {
    const kapal = state.kapals.find((kapal) => kapal.id === id);
    if (kapal) {
      kapal.status = status;
    }
  },
  UPDATE_KAPAL_TRAIL(state, { id, trail }) {
    const kapal = state.kapals.find((kapal) => kapal.id === id);
    if (kapal) {
      kapal.trail = trail;
    }
  },
};

const actions = {
  updateKapalStatus({ commit }, payload) {
    commit('UPDATE_KAPAL_STATUS', payload);
  },
  updateKapalTrail({ commit }, payload) {
    commit('UPDATE_KAPAL_TRAIL', payload);
  },
};

const getters = {
  getKapals: (state) => state.kapals,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
