import { render, staticRenderFns } from "./ReportHeader.vue?vue&type=template&id=580b2f00&scoped=true"
import script from "./ReportHeader.vue?vue&type=script&lang=js"
export * from "./ReportHeader.vue?vue&type=script&lang=js"
import style0 from "./ReportHeader.vue?vue&type=style&index=0&id=580b2f00&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580b2f00",
  null
  
)

export default component.exports