<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="!data.userCreatedTime">Add New User Login</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="usloUserId" class="d-block form-label text-left">
            User Id:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                class="form-control"
                :disabled="data.userCreatedTime"
                type="text"
                id="usloUserId"
                placeholder="User Id"
                v-model="data.usloUserId"
                :class="errors.usloUserId ? 'is-invalid' : ''">
              <b-form-invalid-feedback v-if="errors.usloUserId">
                {{ Object.values(errors.usloUserId) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="usloUserName" class="d-block form-label text-left">
            User Name:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                class="form-control"
                :disabled="data.userCreatedTime"
                type="text"
                id="usloUserName"
                placeholder="User Name"
                v-model="data.usloUserName"
                :class="errors.usloUserName ? 'is-invalid' : ''">
              <b-form-invalid-feedback v-if="errors.usloUserName">
                {{ Object.values(errors.usloUserName) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="usloPassword" class="d-block form-label text-left">
            User Password:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                class="form-control"
                type="password"
                id="usloPassword"
                placeholder="User Password"
                v-model="data.usloPassword"
                :class="errors.usloPassword ? 'is-invalid' : ''">
              <b-form-invalid-feedback v-if="errors.usloPassword">
                {{ Object.values(errors.usloPassword) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <b-button @click="onCancelAddClicked"
                  class="mr-1"
                  size="sm"
                  :disabled="isSubmit"
                  variant="outline-danger">
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button type="submit"
                  @click="onSubmitClicked"
                  :disabled="isSubmit"
                  variant="primary"
                  size="sm"
                  class="ml-1">
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserForm',
  props: ['data', 'errors', 'isSubmit'],
  methods: {
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const data = { ...this.data };
      this.$emit('onSubmitClicked', data);
    },
  },
};
</script>

<style scoped>

</style>
